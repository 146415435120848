import styled, { CSSObject } from '@emotion/styled';
import border from 'styles/border';
import colours from 'styles/colour';
import sizes from 'styles/sizes';
import shadow from 'styles/shadow';

export const inputFocusStyles: React.CSSProperties = {
  border: border.regular,
  borderColor: colours.primary[700],
  boxShadow: `inset 1px 2px 4px rgba(0, 0, 0, 0.01), ${shadow[100]}`,
};

export const baseInputStyles: React.CSSProperties & CSSObject = {
  display: 'block',
  border: 'none',
  backgroundColor: colours.neutral[800],
  borderRadius: border.radius,
  paddingTop: sizes.spacing[300],
  paddingBottom: sizes.spacing[300],
  paddingLeft: sizes.spacing[200],
  paddingRight: sizes.spacing[200],
  marginBottom: sizes.spacing[300],
  boxSizing: 'border-box',
  width: '100%',
  fontSize: sizes.font[200],
  height: sizes.spacing[700],
  color: colours.neutral[100],
  boxShadow: 'inset 1px 2px 8px rgba(0, 0, 0, 0.06)',
  transition: '0.3s ease-out',
  outline: 'none',
  ':focus': {
    ...inputFocusStyles,
  },
};

interface ITextInputProps {
  errorBorder?: boolean;
}

export const TextInput = styled.input<ITextInputProps>((props) => ({
  ...baseInputStyles,
  border: props.errorBorder ? `${border.regular} ${colours.danger}` : 'none',
}));
