import colours from 'styles/colour';
import sizes from 'styles/sizes';

export default {
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  backButton: {
    minWidth: 0,
    color: colours.primary[600],
    height: sizes.spacing[600],
    width: sizes.spacing[600],
  },
};
