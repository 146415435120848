/** @jsx jsx  */
import { jsx } from '@emotion/react';
import React, { useState } from 'react';
import requests from 'utils/requests';
import { sortAlphabeticallyByKey } from 'utils/helpers';
import { useBooking } from 'components/booking-context';
import NextStageFooter from 'components/next-stage-footer';
import ErrorDisplay from 'components/error-display';
import { useQuery } from 'react-query';
import { ButtonSecondary } from 'components/common/button';
import { analyticsEvent, events, pages, usePage } from 'components/analytics';
import { LoadingSpinnerWithText } from 'components/loading-spinner';
import { RefreshCw } from 'lucide-react';
import NewAppointmentHeading from 'components/new-appointment-heading';
import SectionHeading from 'components/section-heading';
import ServiceOption from 'components/service-option';
import { Service, MultiService, Category, ServicesApiResponse } from '../../../../typings';
import { routes } from '../../../../constants';
import styles from './services-select.styles';

const CategoryDivider: React.FC = ({ children }) => {
  return <div css={styles.categoryDivider}>{children}</div>;
};

const ServicesByCategory: React.FC<Props> = ({ services, handleAddService, handleRemoveService, selectedServices }) => {
  const allServicesHaveCategories = services.every((service) => service.categories.length > 0);
  const allNonUniqueCategories = services.map((service) => service.categories).flat();

  const allCategories = [
    ...sortAlphabeticallyByKey(
      allNonUniqueCategories.reduce<Category[]>((acc, category) => {
        if (acc.find((c) => c.id === category.id)) return [...acc];
        return [...acc, category];
      }, []),
      'name'
    ),
    ...(allServicesHaveCategories ? [] : [{ id: 0, name: 'Other' }]),
  ];

  return (
    <React.Fragment>
      {allCategories.map((category) => (
        <React.Fragment>
          <CategoryDivider>{category.name}</CategoryDivider>
          {services
            .filter((service) => {
              if (service.categories.length === 0 && category.id === 0) return true;
              return service.categories.some((c) => c.id === category.id);
            })
            .map((service) => (
              <ServiceOption
                key={service.id}
                onAdd={handleAddService}
                onRemove={handleRemoveService}
                isSelected={selectedServices.includes(service.id)}
                service={service}
              />
            ))}
        </React.Fragment>
      ))}
    </React.Fragment>
  );
};

const ServicesSelect: React.FC = () => {
  const [selectedServices, setSelectedServices] = useState<number[]>([]);
  const [updateErrors, setUpdateErrors] = useState<string[] | null>(null);
  const { updateBooking, booking } = useBooking();
  usePage(pages.selectServices);

  const handleSelectServices = async () => {
    try {
      await updateBooking({ serviceIds: selectedServices });
    } catch (errors) {
      analyticsEvent(events.errors.services(errors));
      setUpdateErrors(errors);
    }
  };

  const handleAddService = (serviceId: number) => {
    setSelectedServices([...selectedServices, serviceId]);
  };

  const handleRemoveService = (serviceId: number) => {
    setSelectedServices(selectedServices.filter((s) => s !== serviceId));
  };

  const { data, error: fetchErrors } = useQuery<ServicesApiResponse, string[]>(
    [routes.api.services, booking.location, booking.user?.id],
    () => requests.get<ServicesApiResponse>(routes.api.services),
    { staleTime: 60000 * 60 }
  );

  if (fetchErrors || updateErrors)
    return (
      <ErrorDisplay errors={fetchErrors ?? ([] || updateErrors) ?? []}>
        <ButtonSecondary onClick={() => window.location.reload()} css={styles.errorButton}>
          <RefreshCw css={styles.errorButtonIcon} />
          Try again
        </ButtonSecondary>
      </ErrorDisplay>
    );

  if (!data) return <LoadingSpinnerWithText text="Loading services..." />;

  const servicesHasCategories = data.services.some((service) => service.categories.length > 0);

  return (
    <React.Fragment>
      <NewAppointmentHeading />
      <SectionHeading>Choose services</SectionHeading>

      {servicesHasCategories ? (
        <ServicesByCategory
          handleAddService={handleAddService}
          handleRemoveService={handleRemoveService}
          selectedServices={selectedServices}
          services={data.services}
        />
      ) : (
        data.services.map((service) => (
          <ServiceOption
            key={service.id}
            onAdd={handleAddService}
            onRemove={handleRemoveService}
            isSelected={selectedServices.includes(service.id)}
            service={service}
          />
        ))
      )}

      {selectedServices.length > 0 && (
        <NextStageFooter
          nextStageAnalyticsEvent={events.buttons.selectServices(selectedServices.map((s) => s))}
          onNextClick={handleSelectServices}
        >
          {selectedServices.length} {selectedServices.length > 1 ? 'services' : 'service'}
        </NextStageFooter>
      )}
    </React.Fragment>
  );
};

interface Props {
  services: (Service | MultiService)[];
  handleAddService: (serviceId: number) => void;
  handleRemoveService: (serviceId: number) => void;
  selectedServices: number[];
}

export default ServicesSelect;
