/** @jsx jsx  */
import { jsx } from '@emotion/react';
import React, { useEffect, useState } from 'react';
import { intervalToDuration, isPast } from 'date-fns';
import { pages, usePage } from 'components/analytics';
import { DepositAppointmentBookingPolicies } from 'components/deposit-policies';
import SectionHeading from 'components/section-heading';
import AppointmentSummary from 'components/appointment-summary';
import { DepositSummary } from 'components/deposit-summary';
import ContactDetailsAndBook from 'components/contact-details-and-book';
import { useBooking } from 'components/booking-context';
import { Location, StaffMember } from 'typings';
import { calculateTotalServicesCost } from 'utils/helpers';
import styles from './confirm-booking.styles';

const CountdownTimer = () => {
  const { booking } = useBooking();
  const [timeToGo, setTimeToGo] = useState<string>('');

  useEffect(() => {
    const interval = setInterval(() => {
      const sessionExpiryDate = new Date(booking.expiresAt);
      const duration = intervalToDuration({ start: new Date(), end: sessionExpiryDate });

      if (isPast(sessionExpiryDate)) {
        window.location.reload();
        return;
      }

      const minutes = `0${duration.minutes}`;
      const seconds = `${(duration.seconds as number) < 10 ? 0 : ''}${duration.seconds}`;
      setTimeToGo(`${minutes}:${seconds}`);
    }, 100);
    return () => clearInterval(interval);
  }, []);

  return <div>{timeToGo}</div>;
};

const ConfirmBooking: React.FC = () => {
  usePage(pages.confirmBooking);
  const { booking } = useBooking();
  const { services, user, location, date, time, isDepositRequired } = booking;

  const totalBookingCost = calculateTotalServicesCost(services);

  return (
    <React.Fragment>
      <div css={styles.topSection}>
        <div>
          <SectionHeading css={styles.sectionHeading}>Confirm your booking</SectionHeading>
        </div>
        <CountdownTimer />
      </div>
      <AppointmentSummary
        services={services}
        user={user as StaffMember}
        location={location as Location}
        totalBookingCost={totalBookingCost}
        date={date as string}
        time={time as string}
      />
      {isDepositRequired && <DepositSummary />}
      <ContactDetailsAndBook />
      {isDepositRequired && <DepositAppointmentBookingPolicies />}
    </React.Fragment>
  );
};

export default ConfirmBooking;
