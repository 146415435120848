import colour from 'styles/colour';
import sizes from 'styles/sizes';

export default {
  errorButton: {
    width: '100%',
    marginTop: sizes.spacing[400],
  },
  errorButtonIcon: {
    marginRight: sizes.spacing[200],
  },

  categoryDivider: {
    marginTop: sizes.spacing[400],
    backgroundColor: colour.neutral[800],
    color: colour.neutral[100],
    fontWeight: 600,
    marginRight: `-${sizes.spacing[400]}`,
    marginLeft: `-${sizes.spacing[400]}`,
    padding: sizes.spacing[400],

    letterSpacing: 1.3,
  },
};
