import colours from 'styles/colour';
import sizes from 'styles/sizes';
import shadow from 'styles/shadow';
import mq from 'styles/breakpoints';

export default {
  verticalStaffMemberCard: {
    textAlign: 'center' as const,
    marginBottom: sizes.spacing[600],
    maxWidth: sizes.spacing[1000],
    minWidth: sizes.spacing[1000],
  },
  avatar: {
    boxShadow: shadow[100],
  },
  avatarSize: sizes.spacing[900],
  jobTitle: {
    color: colours.neutral[400],
    fontSize: sizes.font[100],
    [mq[0]]: {
      fontSize: sizes.font[200],
    },
  },
  button: {
    marginTop: sizes.spacing[200],
    marginLeft: 'auto',
    marginRight: 'auto',
  },
};
