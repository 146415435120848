import axios from 'axios';

axios.defaults.withCredentials = true;

const get = async <T>(url: string): Promise<T> => {
  try {
    const { data } = await axios.get(url);
    return data;
  } catch (error) {
    throw error.response.data.errors;
  }
};

const post = async <T>(url: string, body: unknown): Promise<T> => {
  try {
    const { data } = await axios.post(url, body);
    return data;
  } catch (error) {
    throw error.response.data.errors;
  }
};

const patch = async <T>(url: string, body: unknown): Promise<T> => {
  try {
    const { data } = await axios.patch(url, body);
    return data;
  } catch (error) {
    throw error.response.data.errors;
  }
};

const del = async (url: string) => {
  try {
    const { data } = await axios.delete(url);
    return data;
  } catch (error) {
    throw error.response.data.errors;
  }
};
export default { get, post, del, patch };
