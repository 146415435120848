import colour from 'styles/colour';

const styles = {
  container: {
    textAlign: 'center' as const,
  },
  errorText: { color: colour.danger },
};

export default styles;
