import { css } from '@emotion/react';
import colours from './colour';
import sizes from './sizes';

const FONT_FAMILY = "'Lato', sans-serif;";

export default css`
  @import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;1,100;1,300;1,400&display=swap');

  html {
    font-size: 100%;
  } /*16px*/

  body {
    background: ${colours.neutral[800]};
    font-family: ${FONT_FAMILY};
    font-weight: 400;
    line-height: 1.5;
    color: ${colours.neutral[100]};
    margin: 0;
  }

  hr {
    border: 0;
    height: 3px;
    background: ${colours.neutral[800]};
    margin-top: ${sizes.spacing[400]};
    margin-bottom: ${sizes.spacing[400]};
  }

  p {
    margin-top: 0;
    margin-bottom: ${sizes.spacing[400]};
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    margin: 0 0 1.05rem;
    font-weight: 700;
    line-height: 1;
  }

  h1 {
    font-size: ${sizes.font[600]};
  }

  h2 {
    font-size: ${sizes.font[500]};
  }

  h3 {
    font-size: ${sizes.font[400]};
  }

  h4 {
    font-size: ${sizes.font[300]};
  }

  h5 {
    font-size: ${sizes.font[200]};
  }

  h6 {
    font-size: ${sizes.font[100]};
  }

  small {
    font-size: ${sizes.font[100]};
  }

  button {
    font-family: ${FONT_FAMILY};
  }
`;
