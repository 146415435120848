/** @jsx jsx */
import { jsx } from '@emotion/react';
import { pages, usePage } from 'components/analytics';
import styles from './user-error-boundary.styles';
import { strings } from '../../../constants';

const UserErrorBoundary: React.FC = () => {
  usePage(pages.errorBoundary);

  return (
    <section css={styles.container}>
      <h4>
        <p css={styles.errorText}>Uh oh, there has been an error!</p>
      </h4>
      <p>
        We have already been notified that this error occured, but please let us know about this and how it affected you
        by contacting <a href={`mailto:${strings.supportEmail}`}>{strings.supportEmail}</a>
      </p>
    </section>
  );
};

export default UserErrorBoundary;
