import colours from 'styles/colour';
import sizes from 'styles/sizes';
import border from 'styles/border';
import mq from 'styles/breakpoints';
import { keyframes } from '@emotion/react';
import shadow from 'styles/shadow';

const slideUp = keyframes`
0% {
    transform: translateY(100%)
}
100% {
    transform: translateY(0)
}
`;

export default {
  container: {
    backgroundColor: colours.neutral[900],
    padding: sizes.spacing[200],
    boxShadow: shadow[500],
    borderTopLeftRadius: border.radius,
    borderTopRightRadius: border.radius,
    borderColor: colours.primary[700],
    boxSizing: 'border-box' as const,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'fixed' as const,
    left: '0',
    bottom: '0',
    width: '100%',
    animation: `${slideUp} 0.2s`,
    animationTimingFunction: 'ease',
  },
  infoText: {
    [mq[0]]: {
      fontSize: sizes.font[300],
    },
    fontSize: sizes.font[100],
    color: colours.neutral[400],
  },
  content: {
    fontSize: sizes.font[100],
    [mq[0]]: {
      fontSize: sizes.font[400],
    },
  },
  buttonIcon: {
    color: colours.neutral[300],
    marginLeft: sizes.spacing[100],
  },
};
