/** @jsx jsx  */
import { jsx } from '@emotion/react';
import React from 'react';
import { formatAmountForDisplay } from 'utils/currency';
import * as commonStyles from 'styles/common';
import { formatDateForLocale, formatMinutesAsReadableTime } from 'utils/date';
import AvatarAndName from 'components/avatar-and-name';
import { Service, StaffMember, Location } from 'typings';
import SectionNameHeading from 'components/section-name-heading';
import styles from './appointment-summary.styles';

function AppointmentSummarySectionHeading({ children }: { children: React.ReactNode }) {
  return <h4 css={styles.sectionHeading}>{children}</h4>;
}

interface Props {
  totalBookingCost: number;
  services: Service[];
  user: StaffMember;
  date: string;
  time: string;
  location: Location;
}

const AppointmentSummary: React.FC<Props> = ({ totalBookingCost, services, user, date, time, location }) => {
  return (
    <div css={[commonStyles.card, styles.container]}>
      <div css={styles.headingContainer}>
        <SectionNameHeading css={styles.heading}>Your appointment</SectionNameHeading>
        <AvatarAndName staffMember={user as StaffMember} />
      </div>

      <AppointmentSummarySectionHeading>Services</AppointmentSummarySectionHeading>
      <div css={styles.servicesContainer}>
        <ul css={styles.servicesListContainer}>
          {services.map((service) => (
            <li key={service.id}>
              <div css={styles.serviceAndPriceRow}>
                <div>{service.title}</div>
                <div>{formatAmountForDisplay(service.cost)}</div>
              </div>
              <div css={styles.smallText}>{formatMinutesAsReadableTime(service.duration)}</div>
            </li>
          ))}
        </ul>
      </div>
      <hr />
      <AppointmentSummarySectionHeading>Date & Time</AppointmentSummarySectionHeading>
      <div>
        {formatDateForLocale(date)} at {time}
      </div>
      <hr />
      <AppointmentSummarySectionHeading>Location</AppointmentSummarySectionHeading>
      <div>{`${location.street}, ${location.city}`}</div>
      <hr />

      <div css={styles.costSection}>
        <span css={commonStyles.amountDescriptor}>Total cost: </span>
        <span css={commonStyles.featuredCost}>{formatAmountForDisplay(totalBookingCost)}</span>
      </div>
    </div>
  );
};

export default AppointmentSummary;
