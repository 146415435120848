/** @jsx jsx  */
import { jsx } from '@emotion/react';
import React from 'react';
import colours from 'styles/colour';
import sizes from 'styles/sizes';

const styles = {
  color: colours.neutral[300],
  fontSize: sizes.font[300],
  fontWeight: 400,
  marginBottom: sizes.spacing[400],
  textAlign: 'left' as const,
};

const SectionNameSubHeading: React.FC = ({ children, ...props }) => (
  <h4 css={styles} {...props}>
    {children}
  </h4>
);

export default SectionNameSubHeading;
