import colours from 'styles/colour';
import sizes from 'styles/sizes';
import border from 'styles/border';
import mq from 'styles/breakpoints';
import { CSSObject } from '@emotion/react';

export default {
  container: {
    userSelect: 'none' as const,
    backgroundColor: colours.neutral[900],
    paddingTop: sizes.spacing[400],
    paddingBottom: sizes.spacing[400],
    paddingLeft: sizes.spacing[300],
    paddingRight: sizes.spacing[300],
    borderBottom: border.regular,
    borderColor: colours.neutral[700],
    display: 'flex',
    justifyContent: 'space-between',

    // TODO possibly make this a constant reference so that negative gutters remain consistent after changes
    marginRight: `-${sizes.spacing[400]}`,
    marginLeft: `-${sizes.spacing[400]}`,
  },
  checkbox: {
    display: 'grid',
    gridTemplateColumns: 'min-content auto',
    gridGap: '0.5em',
    fontSize: sizes.font[200],
    color: colours.primary[500],
    [mq[0]]: {
      fontSize: sizes.font[300],
    },
  },
  checkBoxControl: (isSelected: boolean): CSSObject => ({
    display: 'inline-grid',
    width: '1em',
    height: '1em',
    borderRadius: '0.25em',
    border: `0.1em solid ${colours.neutral[400]}`,
    boxShadow: isSelected ? `0 0 0 0.05em ${colours.primary[800]}, 0 0 0.15em 0.1em ${colours.primary[700]}` : 'none',
  }),
  tick: (isSelected: boolean): CSSObject => ({
    transition: 'transform 0.1s ease-in 25ms',
    transform: `scale(${isSelected ? 1 : 0})`,
    transformOrigin: 'bottom left',
  }),
  leftContent: { display: 'flex' },
  title: (isSelected: boolean): CSSObject => ({
    fontWeight: 600,
    fontSize: sizes.font[200],
    marginBottom: sizes.spacing[200],
    color: isSelected ? colours.primary[400] : colours.neutral[100],
    [mq[0]]: {
      fontSize: sizes.font[300],
    },
  }),
  desciption: {
    wordBreak: 'break-word' as const,
    marginBottom: sizes.spacing[200]
  },
  duration: {
    fontSize: sizes.font[100],
    color: colours.neutral[400],
    [mq[0]]: {
      fontSize: sizes.font[300],
    },
  },
  cost: {
    textAlign: 'right' as const,
    fontSize: sizes.font[200],
    [mq[0]]: {
      fontSize: sizes.font[300],
    },
  },
};
