/** @jsx jsx  */
import { jsx } from '@emotion/react';
import React from 'react';
import colours from 'styles/colour';
import sizes from 'styles/sizes';

const styles = {
  color: colours.neutral[200],
  fontSize: sizes.font[400],
  fontWeight: 600,
  marginBottom: sizes.spacing[400],
  textAlign: 'left' as const,
};

const SectionNameHeading: React.FC = ({ children, ...props }) => (
  <h3 css={styles} {...props}>
    {children}
  </h3>
);

export default SectionNameHeading;
