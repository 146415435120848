import { useEffect } from 'react';
import ReactGA from 'react-ga';

export const initializeAnalytics = (trackingId?: string): void => {
  if (process.env.NODE_ENV === 'production') {
    ReactGA.initialize('UA-128053738-2', {
      gaOptions: {
        cookieExpires: 60 * 60 * 24,
        clientId: trackingId,
      },
    });
  }
};

export const usePage = (path: string): void => {
  useEffect(() => {
    ReactGA.pageview(path);
  }, []);
};

export const analyticsEvent = ReactGA.event;
export const analyticsTiming = ReactGA.timing;

export const pages = {
  appointmentManagement: 'Appointment management',
  selectLocation: 'Select location',
  selectStylist: 'Select stylist',
  selectServices: 'Select services',
  selectDate: 'Select date',
  selectTime: 'Select time',
  confirmBooking: 'Confirm booking',
  bookingConfirmation: 'Booking confirmation',
  errorBoundary: 'Error boundary',
};

export const events = {
  buttons: {
    selectLocation: (locationId: number): ReactGA.EventArgs => ({
      category: 'User events',
      action: 'Clicked select location button',
      label: `Selected location id: ${locationId}`,
    }),
    selectStaffMember: (staffMemberId: number): ReactGA.EventArgs => ({
      category: 'User events',
      action: 'Clicked select staff member button',
      label: `Selected staffMember id: ${staffMemberId}`,
    }),
    selectServices: (serviceIds: number[]): ReactGA.EventArgs => ({
      category: 'User events',
      action: 'Clicked next button to select services',
      label: `Selected services ids: ${serviceIds.join(', ')}`,
    }),
    selectDate: (date: Date): ReactGA.EventArgs => ({
      category: 'User events',
      action: 'Clicked next button to select date',
      label: `Selected date: ${date}`,
    }),
    selectTime: (time: string): ReactGA.EventArgs => ({
      category: 'User events',
      action: 'Clicked next button to select time',
      label: `Selected time: ${time}`,
    }),
    confirmBooking: (): ReactGA.EventArgs => ({
      category: 'User events',
      action: 'Clicked submit button to confirm booking',
      label: `Clicked confirm booking at ${Date.now()}`,
    }),
    cancelAppointment: (): ReactGA.EventArgs => ({
      category: 'User events',
      action: 'Clicked cancel appointment button to cancel appointment',
      label: `Clicked cancel appointment at ${Date.now()}`,
    }),
  },
  back: {
    toSelectLocation: {
      category: 'User events',
      action: 'Clicked back button',
      label: `navigated back to location page`,
    },
    toSelectStylist: {
      category: 'User events',
      action: 'Clicked back button',
      label: `navigated back to stylist page`,
    },
    toSelectService: {
      category: 'User events',
      action: 'Clicked back button',
      label: `navigated back to service page`,
    },
    toSelectDate: {
      category: 'User events',
      action: 'Clicked back button',
      label: `navigated back to date page`,
    },
    toSelectTime: {
      category: 'User events',
      action: 'Clicked back button',
      label: `navigated back to time page`,
    },
  },
  errors: {
    location: (errors: string[]): ReactGA.EventArgs => ({
      category: 'API and User errors',
      action: 'Selected location',
      label: `Errors: ${errors.toString()}`,
    }),
    staff: (errors: string[]): ReactGA.EventArgs => ({
      category: 'API and User errors',
      action: 'Selected staff member',
      label: `Errors: ${errors.toString()}`,
    }),
    services: (errors: string[]): ReactGA.EventArgs => ({
      category: 'API and User errors',
      action: 'Selected services',
      label: `Errors: ${errors.toString()}`,
    }),
    date: (errors: string[]): ReactGA.EventArgs => ({
      category: 'API and User errors',
      action: 'Selected date',
      label: `Errors: ${errors.toString()}`,
    }),
    time: (errors: string[]): ReactGA.EventArgs => ({
      category: 'API and User errors',
      action: 'Selected time',
      label: `Errors: ${errors.toString()}`,
    }),
    confirmBooking: (errors: string[]): ReactGA.EventArgs => ({
      category: 'API and User errors',
      action: 'Confirmed booking',
      label: `Errors: ${errors.toString()}`,
    }),
    cancelAppointment: (errors: string[]): ReactGA.EventArgs => ({
      category: 'API and User errors',
      action: 'Cancel appointment',
      label: `Errors: ${errors.toString()}`,
    }),
  },
};

export const timings = {
  fullSuccessfullBookingSession: (time: number): ReactGA.TimingArgs => ({
    category: 'Session times',
    variable: 'Full booking session confirmed from start to finish',
    value: time,
  }),
  bookingStartedAndMovedToPayDeposit: (time: number): ReactGA.TimingArgs => ({
    category: 'Session times',
    variable: 'Booking flow completed and moved to pay deposit screen (Stripe)',
    value: time,
  }),
};
