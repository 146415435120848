import { SECTION_SPACING } from 'styles/common';

export default {
  container: {
    marginBottom: SECTION_SPACING,
    display: 'flex',
    justifyContent: 'space-between',
  },
  sectionHeading: {
    marginBottom: 0,
  },
};
