import sizes from 'styles/sizes';

export default {
  topSection: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: sizes.spacing[400],
  },
  sectionHeading: {
    marginBottom: 0,
  },
};
