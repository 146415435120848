/** @jsx jsx  */
import { jsx } from '@emotion/react';
import React, { useState } from 'react';
import Avatar from 'components/avatar';
import * as commonStyles from 'styles/common';
import { ButtonWithSpinner } from 'components/common/button';
import { analyticsEvent, events } from 'components/analytics';
import { DEFAULT_STAFF_PHOTO_URL } from '../../constants';
import styles from './vertical-staff-member-card.styles';
import { StaffMemberCardProps } from './types';

export const VerticalStaffMemberCard: React.FC<StaffMemberCardProps> = ({ staffMember, onSelect }) => {
  const [isSelected, setIsSelected] = useState<boolean>(false);

  return (
    <div css={[commonStyles.card, styles.verticalStaffMemberCard]}>
      <Avatar
        css={styles.avatar}
        src={staffMember.photoUrl || DEFAULT_STAFF_PHOTO_URL}
        alt={`Stylist, ${staffMember.fullName}`}
        size={styles.avatarSize}
      />

      <div>{staffMember.fullName}</div>
      <div css={styles.jobTitle}>{staffMember.jobTitle}</div>
      <ButtonWithSpinner
        css={styles.button}
        loading={isSelected}
        type="button"
        onClick={() => {
          analyticsEvent(events.buttons.selectStaffMember(staffMember.id));
          setIsSelected(true);
          onSelect(staffMember.id);
        }}
      >
        Select
      </ButtonWithSpinner>
    </div>
  );
};
