import Dinero from 'dinero.js';
import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import LocationSelect from './location-select';
import ServicesSelect from './services-select';
import { BookingProvider, useBooking } from '../../booking-context';
import StaffSelect from './staff-select';
import DateSelect from './date-select';
import TimeSelect from './time-select';
import ConfirmBooking from './confirm-booking';
import BookingConfirmation from './booking-confirmation';
import Layout from './public-booking-layout';
import ErrorDisplay from '../../error-display';
import { LoadingSpinnerWithText } from '../../loading-spinner';
import { routes } from '../../../constants';

const Booking = () => {
  const { error, booking, isBookingComplete, isLoading, isBookingInitialized } = useBooking();

  useEffect(() => {
    if (booking) {
      Dinero.defaultCurrency = booking.currency;
    }
  }, [booking]);

  if (error) {
    return <ErrorDisplay errors={error} />;
  }
  if (isLoading) {
    return <LoadingSpinnerWithText text={isLoading} />;
  }

  if (isBookingInitialized) {
    if (isBookingComplete) return <BookingConfirmation />;
    const { location, user, services, date, time } = booking;

    if (!location) return <LocationSelect />;
    if (!user) return <StaffSelect />;
    if (services.length === 0) return <ServicesSelect />;
    if (!date) return <DateSelect />;
    if (!time) return <TimeSelect />;
    return <ConfirmBooking />;
  }

  return null;
};

export const PublicBooking: React.FC = () => {
  return (
    <BookingProvider>
      <Layout>
        <Switch>
          {/* TODO There is the call being made to start a new session/get old session on complete because we are in the booking context here. A refactor should probably see this handled differently for now it will do until we refactor public booking setup */}
          <Route path={`/:slug${routes.app.bookingSuccess}`}>
            <BookingConfirmation />
          </Route>
          <Route path="*">
            <Booking />
          </Route>
        </Switch>
      </Layout>
    </BookingProvider>
  );
};
