import colours from 'styles/colour';
import sizes from 'styles/sizes';
import shadow from 'styles/shadow';
import border from 'styles/border';

export const SECTION_SPACING = sizes.spacing[500];

export const card = {
  boxShadow: shadow[100],
  backgroundColor: colours.neutral[900],
  padding: sizes.spacing[400],
  borderRadius: border.radius,
};

export const amountDescriptor = {
  fontSize: sizes.font[200],
  color: colours.neutral[400],
};

export const featuredCost = {
  fontWeight: 600,
  color: colours.neutral[100],
};
