/** @jsx jsx  */
import { jsx } from '@emotion/react';
import sizes from 'styles/sizes';

const textStyles = {
  textAlign: 'justify' as const,
  fontSize: sizes.font[200],
};

export const PolicyParagraph: React.FC = ({ children, ...props }) => {
  return (
    <p css={textStyles} {...props}>
      {children}
    </p>
  );
};
