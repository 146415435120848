/** @jsx jsx  */
import { jsx } from '@emotion/react';
import React from 'react';
import { Link, LinkProps } from 'react-router-dom';
import styled, { CSSObject } from '@emotion/styled';
import colours from 'styles/colour';
import border from 'styles/border';
import shadow from 'styles/shadow';
import sizes from 'styles/sizes';
import LoadingSpinner from '../../loading-spinner';

const baseButtonStyles: CSSObject = {
  border: 'none',
  background: colours.neutral[900],
  boxShadow: shadow[100],
  borderRadius: border.radius,
  paddingTop: sizes.spacing[300],
  paddingBottom: sizes.spacing[300],
  paddingLeft: sizes.spacing[200],
  paddingRight: sizes.spacing[200],
  fontSize: sizes.font[200],
  fontWeight: 500,
  fontFamily: 'inherit',
  textAlign: 'center',
  textDecoration: 'none',
  letterSpacing: '.04rem',
  textTransform: 'uppercase',
  overflow: 'hidden',
  minWidth: sizes.spacing[900],
  ':disabled': {
    backgroundColor: colours.neutral[700],
    cursor: 'not-allowed',
  },
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

export const BaseButton = styled.button(baseButtonStyles);

const primaryButtonStyles = { color: colours.neutral[100], border: border.regular, borderColor: colours.primary[100] };

export const Button = styled(BaseButton)({
  ...primaryButtonStyles,
});

interface ISecondaryButton {
  whiteText?: boolean;
}

export const ButtonSecondary = styled(Button)(
  {
    background: 'transparent',
    border: `${border.regular} ${colours.neutral[100]}`,
  },
  (props: ISecondaryButton) => ({ color: props.whiteText ? colours.neutral[900] : colours.neutral[100] })
);

export const ButtonTertiary = styled(Button)(() => ({
  background: 'transparent',
  color: colours.neutral[300],
}));

export const ButtonDanger = styled(Button)({
  border: `${border.regular} ${colours.danger}`,

  color: colours.neutral[100],
});

interface IButtonWithSpinner extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  loading: boolean;
}

export const ButtonWithSpinner: React.FC<IButtonWithSpinner> = ({ loading, children, ...props }) => {
  return (
    <Button disabled={loading} {...props}>
      {loading ? <LoadingSpinner size={16} /> : children}
    </Button>
  );
};

export const buttonNakedStyles = {
  border: '0',
  background: 'transparent',
  padding: '0',
  margin: '0',
  fontFamily: 'inherit',
  cursor: 'pointer',
  color: 'inherit',
};

type ButtonSize = 'small' | 'regular' | 'large';

export interface ButtonProps
  extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  isLoading?: boolean;
  size?: ButtonSize;
}
export const ButtonNaked = React.forwardRef<HTMLButtonElement, ButtonProps>(({ children, ...props }, ref) => (
  <button type="button" css={buttonNakedStyles} {...props} ref={ref}>
    {children}
  </button>
));

type CompatibleButtonProps = Pick<ButtonProps, 'size'>;

export const RouterLinkAsButton: React.FC<LinkProps & CompatibleButtonProps> = ({ ...props }) => (
  <Link css={{ ...baseButtonStyles, ...primaryButtonStyles }} {...props} />
);
