import colour from 'styles/colour';
import sizes from 'styles/sizes';

export default {
  cancelButtonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  confirmCancelButtonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  cancelText: {
    textAlign: 'center' as const,
  },
  cancelIcon: {
    marginRight: sizes.spacing[100],
    color: colour.danger,
  },
};
