const sizes = {
  spacing: {
    100: '0.25rem', // 4px
    200: '0.5rem', // 8px
    300: '0.75rem', // 12px
    400: '1rem', // 16px
    500: '1.5rem', // 24px
    600: '2rem', // 32px
    700: '3rem', // 48px
    800: '4rem', // 64px
    900: '6rem', // 96px
    1000: '8rem', // 128px
    1100: '12rem', // 192px
    1200: '16rem', // 256px
    1300: '24rem', // 384px
    1400: '32rem', // 512px
    1500: '40rem', // 640px
    1600: '48rem' // 768px
  },
  font: {
    100: '0.75rem', // 12px
    200: '0.875rem', // 14px
    300: '1rem', // 16px (base)
    400: '1.125rem', // 18px
    500: '1.25rem', // 20px
    600: '1.5rem', // 24px
    700: '1.875rem', // 30px
    800: '2.25rem', // 36px
    900: '3rem', // 48px
    1000: '3.75rem', // 60px
    1100: '4.5rem' // 72px
  }
};

export default sizes;
