import colours from 'styles/colour';
import sizes from 'styles/sizes';

export default {
  container: {
    marginBottom: sizes.spacing[500],
  },
  formGroup: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: sizes.spacing[300],
  },
  smallText: {
    color: colours.neutral[400],
    fontSize: sizes.font[100],
  },
  bottomContainer: { marginTop: sizes.spacing[500], display: 'flex', justifyContent: 'flex-end' },
  formItem: {
    width: '50%',
    ' :first-of-type': {
      marginRight: sizes.spacing[200],
    },
    ' :last-child': {
      marginLeft: sizes.spacing[200],
    },
  },
  formError: {
    fontSize: sizes.font[200],
    color: colours.danger,
  },
  buttonIcon: {
    color: colours.neutral[300],
    marginLeft: sizes.spacing[100],
  },
  errorButton: {
    width: '100%',
    marginTop: sizes.spacing[400],
  },
  errorButtonIcon: {
    marginRight: sizes.spacing[200],
  },
};
