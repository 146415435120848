import { CSSObject } from '@emotion/react';
import colours from 'styles/colour';

const styles = {
  avatar: (size: string): CSSObject => ({
    width: size,
    height: size,
    objectFit: 'cover' as const,
    borderRadius: '50%',
    borderColor: colours.primary[500],
    borderWidth: '3px',
    borderStyle: 'solid',
  }),
};

export default styles;
