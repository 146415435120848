import { CSSObject } from '@emotion/serialize';
import colour from 'styles/colour';
import sizes from 'styles/sizes';

export default {
  container: {
    maxWidth: sizes.spacing[1400],
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  errorButton: {
    width: '100%',
    marginTop: sizes.spacing[400],
  },
  calendarIcon: {
    marginRight: sizes.spacing[200],
  },
  datePicker: {
    ' > div': {
      display: 'flex',
      justifyContent: 'center',
    },
    marginBottom: sizes.spacing[400],
  },
  noAvailabilityForMonthContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column' as const,
    alignItems: 'center',
  },
  noAvailabilityForMonthIcon: {
    color: colour.neutral[300],
  },
  light: (lightColour: 'GREEN' | 'AMBER'): CSSObject => {
    const availabilityColour = lightColour === 'GREEN' ? colour.trafficGreen : colour.trafficAmber;

    return {
      margin: 'auto',
      backgroundColor: availabilityColour,
      width: '4px',
      height: '4px',
      borderRadius: '50%',
      boxShadow: `0 0 4px ${availabilityColour}`,
      position: `relative`,
      bottom: '2px',
    };
  },
};
