/** @jsx jsx  */
import { jsx } from '@emotion/react';
import React, { useState } from 'react';
import { eachDayOfInterval, isBefore, isSameDay, startOfDay } from 'date-fns';
import DatePicker from 'components/datepicker';
import ErrorDisplay from 'components/error-display';
import { useQuery } from 'react-query';
import { DatesApiResponse, LocationClosure, TimeSlotCountKeyedByDate } from 'typings';
import requests from 'utils/requests';
import { formatDateForLocale, formatDateForStore, newTimeZoneAgnosticDateFromDateString } from 'utils/date';
import { analyticsEvent, events, pages, usePage } from 'components/analytics';
import { ButtonSecondary } from 'components/common/button';
import { Calendar, CalendarOff } from 'lucide-react';
import NextStageFooter from 'components/next-stage-footer';
import { useBooking } from 'components/booking-context';
import NewAppointmentHeading from 'components/new-appointment-heading';
import SectionHeading from 'components/section-heading';
import { LoadingSpinnerWithText } from 'components/loading-spinner';
import { routes } from '../../../../constants';
import styles from './date-select.styles';

function isLocationOpenOnDate(date: Date, locationClosures: Date[]) {
  return !locationClosures.some((locationClosure) => isSameDay(locationClosure, date));
}

function hasSlotsOnDay(date: Date, availability: TimeSlotCountKeyedByDate) {
  return Boolean(availability[formatDateForStore(date)]) && availability[formatDateForStore(date)] > 0;
}

function getAvailabilityLightColour(numberOfSlots: number) {
  if (numberOfSlots >= 12) {
    return 'GREEN';
  }
  if (numberOfSlots > 0) {
    return 'AMBER';
  }
  return 'NONE';
}

const getEveryDateInLocationClosures = (locationClosures: LocationClosure[]) => {
  return locationClosures.flatMap((locationClosureRange) => {
    const eachDateOfInterval = eachDayOfInterval({
      start: newTimeZoneAgnosticDateFromDateString(locationClosureRange.startDate),
      end: newTimeZoneAgnosticDateFromDateString(locationClosureRange.endDate),
    });

    return eachDateOfInterval;
  });
};

interface NoAvailabilityForMonthProps {
  date: Date;
}

const NoAvailabilityForMonth: React.FC<NoAvailabilityForMonthProps> = ({ date }) => {
  return (
    <div css={styles.noAvailabilityForMonthContainer}>
      <div>
        <CalendarOff css={styles.noAvailabilityForMonthIcon} size={30} />
      </div>
      <div>No availability in {formatDateForLocale(date, 'MMMM')}</div>
    </div>
  );
};

const DateSelect: React.FC = () => {
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [watchedCalendarViewDate, setWatchedCalendarViewDate] = useState<Date>(new Date());
  const [updateErrors, setUpdateErrors] = useState<string[] | null>(null);
  const { updateBooking, booking } = useBooking();

  const datesURL = `${routes.api.dates}?month=${
    watchedCalendarViewDate.getMonth() + 1
  }&year=${watchedCalendarViewDate.getUTCFullYear()}}`;

  const {
    data,
    isLoading,
    error: fetchErrors,
  } = useQuery<DatesApiResponse, string[]>(
    [datesURL, booking.location, booking.user?.id, booking.services.map((s) => `service-${s.id}`).join('')],
    () => requests.get<DatesApiResponse>(datesURL),
    { staleTime: 60000 * 5 }
  );

  usePage(pages.selectDate);

  if (fetchErrors) {
    return (
      <ErrorDisplay errors={fetchErrors}>
        <ButtonSecondary onClick={() => window.location.reload()} css={styles.errorButton}>
          <Calendar css={styles.calendarIcon} />
          Try again
        </ButtonSecondary>
      </ErrorDisplay>
    );
  }

  const availability =
    data?.availability.reduce<TimeSlotCountKeyedByDate>((acc, curr) => {
      acc[curr.date] = curr.count;
      return acc;
    }, {}) ?? {};

  const locationClosures = getEveryDateInLocationClosures(data?.locationClosures ?? []);

  const handleSelectDate = async () => {
    try {
      await updateBooking({ date: formatDateForStore(selectedDate as Date) });
    } catch (errors) {
      analyticsEvent(events.errors.date(errors));
      setUpdateErrors(errors);
    }
  };

  const renderDayContents = (day: number, date?: Date) => {
    const DefaultDay = () => <div>{day}</div>;

    if (date) {
      const isDateBeforeToday = isBefore(date, startOfDay(new Date()));
      const dateKey = formatDateForStore(date);
      const numberOfSlots = availability[dateKey];

      const colour = getAvailabilityLightColour(numberOfSlots);

      if (
        isDateBeforeToday ||
        !isLocationOpenOnDate(date, locationClosures) ||
        numberOfSlots === 0 ||
        !numberOfSlots ||
        colour === 'NONE'
      ) {
        return <DefaultDay />;
      }

      return (
        <div>
          <div>{day}</div>
          <div css={styles.light(colour)} />
        </div>
      );
    }
    return <DefaultDay />;
  };

  if (updateErrors)
    return (
      <ErrorDisplay errors={updateErrors}>
        <ButtonSecondary onClick={() => window.location.reload()} css={styles.errorButton}>
          <Calendar css={styles.calendarIcon} />
          Pick another date
        </ButtonSecondary>
      </ErrorDisplay>
    );

  return (
    <React.Fragment>
      <NewAppointmentHeading />
      <SectionHeading>Choose a date</SectionHeading>
      <div css={styles.datePicker}>
        <DatePicker
          inline
          selected={selectedDate}
          onChange={(date: Date) => setSelectedDate(date)}
          shouldCloseOnSelect={false}
          minDate={new Date()}
          filterDate={(date) => isLocationOpenOnDate(date, locationClosures) && hasSlotsOnDay(date, availability)}
          renderDayContents={renderDayContents}
          onMonthChange={setWatchedCalendarViewDate}
        />
      </div>
      {!isLoading && Object.values(availability).length === 0 && (
        <NoAvailabilityForMonth date={watchedCalendarViewDate} />
      )}
      {isLoading && (
        <div>
          <LoadingSpinnerWithText text="Loading availability..." />
        </div>
      )}

      {selectedDate && (
        <NextStageFooter
          nextStageAnalyticsEvent={events.buttons.selectDate(selectedDate)}
          onNextClick={handleSelectDate}
        >
          {formatDateForLocale(selectedDate)}
        </NextStageFooter>
      )}
    </React.Fragment>
  );
};

export default DateSelect;
