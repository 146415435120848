/** @jsx jsx  */
import { jsx, Global } from '@emotion/react';
import { Route, Switch } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import datePickerStyles from 'components/datepicker/datepicker-styles';
import globalStyles from 'styles/global';
import { AppointmentManagement } from './pages/appointment-management';
import { PublicBooking } from './pages/public-booking';

const queryClient = new QueryClient();

function App(): JSX.Element {
  return (
    <div className="App">
      <Global styles={[globalStyles, datePickerStyles]} />
      <QueryClientProvider client={queryClient}>
        <Switch>
          <Route path={['/:slug/appointments/:appointmentId']}>
            <AppointmentManagement />
          </Route>
          <Route path={['/:slug', '/']}>
            <PublicBooking />
          </Route>
        </Switch>
      </QueryClientProvider>
    </div>
  );
}

export default App;
