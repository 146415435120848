import { compareAsc, isFuture } from 'date-fns';
import { Service, MultiService, AppointmentService, DepositType, PublicAppointment } from 'typings';
import { routes } from '../constants';
import { newTimeZoneAgnosticDateFromDateString } from './date';

export const calculateTotalServicesCost = (services: (Service | MultiService)[]): number => {
  return services.reduce((accumulator, service) => accumulator + service.cost, 0);
};

export const calculateDepositAmountDue = (type: DepositType, depositAmount: number, totalBookingCost: number) => {
  if (type === 'FLAT') {
    return depositAmount;
  }

  return (depositAmount / 100) * totalBookingCost;
};

export const sortAppointmentServicesByStartTime = (appointmentServices: AppointmentService[]): AppointmentService[] => {
  return appointmentServices.sort((a, b) => {
    const dateA = new Date();
    const dateB = new Date(dateA);

    const [hoursA, minutesA] = a.startTime.split(':');
    const [hoursB, minutesB] = b.startTime.split(':');

    dateA.setHours(parseInt(hoursA, 10), parseInt(minutesA, 10));
    dateB.setHours(parseInt(hoursB, 10), parseInt(minutesB, 10));

    return compareAsc(dateA, dateB);
  });
};

export const newDateFromAppointment = (appointment: PublicAppointment) => {
  const date = newTimeZoneAgnosticDateFromDateString(appointment.date);
  const [hours, minutes] = sortAppointmentServicesByStartTime(appointment.appointmentServices)[0].startTime.split(':');
  date.setHours(parseInt(hours, 10), parseInt(minutes, 10));
  return date;
};

export const appointmentIsInTheFuture = (appointment: PublicAppointment) => {
  const appointmentDate = newDateFromAppointment(appointment);
  return isFuture(appointmentDate);
};

export function sortAlphabeticallyByKey<T, K extends keyof T>(arr: T[], key: K): T[] {
  const sortedArrayOfObjects = arr.sort((a, b) => {
    if (typeof a[key] === 'string' && typeof b[key] === 'string') {
      // @ts-ignore
      if (a[key].toUpperCase() < b[key].toUpperCase()) {
        return -1;
      }
      // @ts-ignore
      if (a[key].toUpperCase() > b[key].toUpperCase()) {
        return 1;
      }
    }

    return 0;
  });
  return sortedArrayOfObjects;
}

export const buildBusinessNewAppointmentRoute = (slug: string): string => `${routes.app.home}${slug}`;
