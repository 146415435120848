/** @jsx jsx  */
import { jsx } from '@emotion/react';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import requests from 'utils/requests';
import { useBooking } from 'components/booking-context';
import ErrorDisplay from 'components/error-display';
import { ButtonSecondary } from 'components/common/button';
import { LoadingSpinnerWithText } from 'components/loading-spinner';
import { RefreshCw } from 'lucide-react';
import NewAppointmentHeading from 'components/new-appointment-heading';
import SectionHeading from 'components/section-heading';
import LocationCard from 'components/location-card';
import styles from './location-select.styles';
import { LocationsApiResponse } from '../../../../typings';
import { routes } from '../../../../constants';
import { usePage, pages, analyticsEvent, events } from '../../../analytics';

const LocationSelect: React.FC = () => {
  const { updateBooking } = useBooking();
  const [updateErrors, setUpdateErrors] = useState<string[] | null>(null);
  usePage(pages.selectLocation);

  const { data, error: fetchErrors } = useQuery<LocationsApiResponse, string[]>(
    routes.api.locations,
    () => requests.get<LocationsApiResponse>(routes.api.locations),
    { staleTime: 60000 * 60 }
  );

  const handleSelectLocation = async (locationId: number) => {
    try {
      await updateBooking({ locationId });
    } catch (errors) {
      analyticsEvent(events.errors.location(errors));
      setUpdateErrors(errors);
    }
  };

  if (fetchErrors || updateErrors)
    return (
      <ErrorDisplay errors={fetchErrors ?? ([] || updateErrors) ?? []}>
        <ButtonSecondary onClick={() => window.location.reload()} css={styles.errorButton}>
          <RefreshCw css={styles.errorButtonIcon} />
          Try again
        </ButtonSecondary>
      </ErrorDisplay>
    );
  if (!data) return <LoadingSpinnerWithText text="Loading locations..." />;

  return (
    <React.Fragment>
      <NewAppointmentHeading />
      <SectionHeading>Choose a location</SectionHeading>
      {data.locations.map((location) => (
        <LocationCard key={location.id} onSelect={handleSelectLocation} location={location} />
      ))}
    </React.Fragment>
  );
};

export default LocationSelect;
