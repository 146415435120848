/** @jsx jsx  */
import { jsx } from '@emotion/react';
import SectionNameHeading from 'components/section-name-heading';
import SectionNameSubHeading from 'components/section-name-sub-heading';
import { useBooking } from 'components/booking-context';
import { calculateDepositAmountDue, calculateTotalServicesCost } from 'utils/helpers';
import { PolicyParagraph } from 'components/policy-paragraph';
import { formatAmountForDisplay } from 'utils/currency';
import { Card } from 'components/common/card';

export const PoliciesHeading = () => <SectionNameHeading>Our policies</SectionNameHeading>;

export const DEPOSIT_CANCELLATION_POLICY_ID = 'deposit-cancellation-policy';
interface DepositCancellationPolicyProps {
  cancellationGracePeriod: number;
}
export const DepositCancellationPolicy = ({ cancellationGracePeriod }: DepositCancellationPolicyProps) => {
  return (
    <div id={DEPOSIT_CANCELLATION_POLICY_ID}>
      <SectionNameSubHeading>Cancellations</SectionNameSubHeading>
      <PolicyParagraph>
        If you cancel your appointment less than {cancellationGracePeriod} days before your booking is due to start you
        will lose your deposit.
      </PolicyParagraph>
      <PolicyParagraph>
        You will receive a full refund of your deposit if you cancel your appointment more than{' '}
        {cancellationGracePeriod} days before your appointment start time.
      </PolicyParagraph>
    </div>
  );
};

interface AppointmentManagementDepositBookingPoliciesProps {
  cancellationGracePeriod: number;
}

export const AppointmentManagementDepositBookingPolicies = ({
  cancellationGracePeriod,
  ...props
}: AppointmentManagementDepositBookingPoliciesProps) => {
  return (
    <Card {...props}>
      <PoliciesHeading />
      <DepositCancellationPolicy cancellationGracePeriod={cancellationGracePeriod} />
    </Card>
  );
};

export const DepositAppointmentBookingPolicies = () => {
  const { booking } = useBooking();
  const { depositAmount, services, cancellationGracePeriod, depositType } = booking;
  const totalBookingCost = calculateTotalServicesCost(services);

  // TODO pub booking needs a refactor now so that initial booking doesnt have daft nulls
  if (cancellationGracePeriod === null || depositAmount === null || !depositType) {
    return null;
  }

  const depositDue = calculateDepositAmountDue(depositType, depositAmount, totalBookingCost);

  return (
    <section>
      <PoliciesHeading />
      <PolicyParagraph>
        A booking deposit of {formatAmountForDisplay(depositDue)} is required to confirm your appointment. This amount
        will be deducted from the final amount owed on your services. You will lose your deposit if you miss or cancel
        your appointment after the cancellation grace period.
      </PolicyParagraph>
      <DepositCancellationPolicy cancellationGracePeriod={cancellationGracePeriod} />
    </section>
  );
};
