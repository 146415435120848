/** @jsx jsx  */
import { jsx } from '@emotion/react';
import React, { useState } from 'react';
import * as commonStyles from 'styles/common';
import SectionNameHeading from 'components/section-name-heading';
import MobilePhoneInput from 'components/phone-input';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useBooking } from 'components/booking-context';
import requests from 'utils/requests';
import { Check, RefreshCw } from 'lucide-react';
import ErrorDisplay from 'components/error-display';
import { analyticsEvent, events } from 'components/analytics';
import { routes } from '../../constants';
import styles from './contact-details-and-book.styles';
import { TextInput, Label, ButtonWithSpinner, ButtonSecondary } from '../common';
import { schema } from './contact-details.schema';

import 'react-phone-input-2/lib/style.css';

interface ContactDetails {
  firstName: string;
  lastName: string;
  email: string;
  mobileNumber: string;
}

const ContactDetailsAndBook: React.FC = () => {
  const { completeNonDepositBooking, beginDepositFlow, booking } = useBooking();
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<ContactDetails>({ resolver: yupResolver(schema) });
  const [bookingErrors, setBookingErrors] = useState<string[] | null>(null);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const onSubmit = async (data: ContactDetails) => {
    try {
      analyticsEvent(events.buttons.confirmBooking());
      setIsSubmitting(true);
      const response = await requests.post<{ url?: string }>(routes.api.appointments, data);
      if (response?.url && booking.isDepositRequired) {
        beginDepositFlow(response.url);
      } else {
        completeNonDepositBooking();
      }
    } catch (apiErrors) {
      analyticsEvent(events.errors.confirmBooking(apiErrors as string[]));
      setBookingErrors(apiErrors as string[]);
    }
  };

  if (bookingErrors) {
    return (
      <ErrorDisplay errors={bookingErrors}>
        <ButtonSecondary onClick={() => window.location.reload()} css={styles.errorButton}>
          <RefreshCw css={styles.errorButtonIcon} />
          Try again
        </ButtonSecondary>
      </ErrorDisplay>
    );
  }

  if (!booking) {
    return null;
  }

  return (
    <div css={[commonStyles.card, styles.container]}>
      <SectionNameHeading>Contact details</SectionNameHeading>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div css={styles.formGroup}>
          <div css={styles.formItem}>
            <Label htmlFor="firstName">First name*</Label>
            <TextInput type="text" {...register('firstName')} />
            {errors.firstName && <div css={styles.formError}>{errors?.firstName.message}</div>}
          </div>
          <div css={styles.formItem}>
            <Label htmlFor="lastName">Last name*</Label>
            <TextInput type="text" {...register('lastName')} />
            {errors.lastName && <div css={styles.formError}>{errors?.lastName.message}</div>}
          </div>
        </div>

        <div css={styles.formGroup}>
          <div css={styles.formItem}>
            <Label htmlFor="email">Email*</Label>
            <TextInput type="text" {...register('email')} />
            {errors.email && <div css={styles.formError}>{errors?.email.message}</div>}
          </div>

          <div css={styles.formItem}>
            <Label htmlFor="mobileNumber">Phone number*</Label>
            <Controller
              control={control}
              name="mobileNumber"
              render={({ field }) => <MobilePhoneInput location={booking.location.country} {...field} />}
            />
            {errors.mobileNumber && <div css={styles.formError}>{errors?.mobileNumber.message}</div>}
          </div>
        </div>

        <div css={styles.bottomContainer}>
          <ButtonWithSpinner loading={isSubmitting} type="submit">
            {booking.isDepositRequired ? 'Pay Deposit & Book' : 'Book'} <Check css={styles.buttonIcon} size={16} />
          </ButtonWithSpinner>
        </div>
      </form>
    </div>
  );
};

export default ContactDetailsAndBook;
