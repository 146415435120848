import React, { useState, useEffect } from 'react';
import { analyticsEvent, events, analyticsTiming, timings, initializeAnalytics } from 'components/analytics';
import { BookingState, BookingStateUpdate, PublicBookingApiResponse } from 'typings';
import { useParams } from 'react-router-dom';
import { routes } from '../../constants';
import requests from '../../utils/requests';

interface IBookingContext {
  booking: BookingState;
  updateBooking: (data: Partial<BookingStateUpdate>) => Promise<void>;
  error: Error | string[] | undefined;
  isBookingComplete: boolean;
  completeNonDepositBooking: () => void;
  beginDepositFlow: (depositFlowUrl: string) => void;
  isLoading: false | string;
  back: () => void;
  isBookingInitialized: boolean;
}

interface UrlParams {
  slug: string;
}

// TODO would be nice if i could get rid of this
const DEFAULT_BOOKING_VALUES = {
  depositType: null,
  isDepositRequired: false,
  depositAmount: null,
  cancellationGracePeriod: null,
  businessName: null,
  location: null,
  services: [],
  user: null,
  date: null,
  time: null,
  trackingId: null,
  expiresAt: '',
};

const BookingContext = React.createContext({} as IBookingContext);

const BookingProvider: React.FC = ({ children }) => {
  // The way that DEFAULT_BOOKING_VALUES is setup is a bit crap, it should really be something like BookingState | null rather than all the individual fields nullified
  // eslint-disable-next-line
  //@ts-ignore
  const [booking, setBooking] = useState<BookingState>(DEFAULT_BOOKING_VALUES);
  const [isLoading, setIsLoading] = useState<string | false>(false);
  const [isBookingInitialized, setIsBookingInitialized] = useState<boolean>(false);
  const [error, setError] = useState<Error | string[] | undefined>(undefined);
  const [isBookingComplete, setIsBookingComplete] = useState<boolean>(false);
  const { slug } = useParams<UrlParams>();
  const [bookingStartTime] = useState<number>(Date.now());

  useEffect(() => {
    const intialiseBooking = async () => {
      try {
        setIsLoading('Loading booking session...');
        const { publicBooking } = await requests.post<PublicBookingApiResponse>(routes.api.publicBooking, {
          slug,
        });
        initializeAnalytics(publicBooking.trackingId as string);
        setBooking(publicBooking);
        setIsBookingInitialized(true);
      } catch (e) {
        setError(e);
      } finally {
        setIsLoading(false);
      }
    };

    intialiseBooking();
  }, [slug]);

  useEffect(() => {
    if (booking.businessName) {
      document.title = `Book with ${booking.businessName}`;
    }
  }, [booking]);

  const updateBooking = async (data: Partial<BookingStateUpdate>) => {
    const { publicBooking } = await requests.patch<PublicBookingApiResponse>(routes.api.publicBooking, data);
    setBooking(publicBooking);
  };

  const completeNonDepositBooking = () => {
    analyticsTiming(timings.fullSuccessfullBookingSession(Date.now() - bookingStartTime));
    setIsBookingComplete(true);
  };

  const beginDepositFlow = (depositFlowUrl: string) => {
    analyticsTiming(timings.bookingStartedAndMovedToPayDeposit(Date.now() - bookingStartTime));
    window.location.replace(depositFlowUrl);
  };

  const back = async () => {
    setIsLoading('Updating booking session...');
    const updateData: Partial<BookingStateUpdate> = {};
    if (!booking.user) {
      analyticsEvent(events.back.toSelectLocation);
      updateData.locationId = null;
    } else if (booking.services.length === 0) {
      analyticsEvent(events.back.toSelectStylist);
      updateData.userId = null;
    } else if (!booking.date) {
      analyticsEvent(events.back.toSelectService);
      updateData.serviceIds = [];
    } else if (!booking.time) {
      analyticsEvent(events.back.toSelectDate);
      updateData.date = null;
    } else {
      analyticsEvent(events.back.toSelectTime);
      updateData.time = null;
    }
    try {
      await updateBooking(updateData);
    } catch (updateError) {
      setError(updateError);
    }
    setIsLoading(false);
  };

  return (
    <BookingContext.Provider
      value={{
        booking,
        updateBooking,
        error,
        isBookingComplete,
        completeNonDepositBooking,
        beginDepositFlow,
        isLoading,
        back,
        isBookingInitialized,
      }}
    >
      {children}
    </BookingContext.Provider>
  );
};

const useBooking = (): IBookingContext => React.useContext(BookingContext);

export { BookingProvider, useBooking };
