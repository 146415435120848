/** @jsx jsx  */
import { jsx } from '@emotion/react';
import React, { useState } from 'react';
import { ButtonWithSpinner } from 'components/common/button';
import { analyticsEvent, events } from 'components/analytics';
import { Location } from '../../typings';
import styles from './location-card.styles';

interface LocationCardProps {
  location: Location;
  onSelect(id: number): void;
}

const LocationCard: React.FC<LocationCardProps> = ({ location, onSelect }) => {
  const [isSelected, setIsSelected] = useState<boolean>(false);

  return (
    <div css={styles.container}>
      {location.photoUrl && 
      <div css={styles.imageContainer}>
        <img css={styles.image} src={location.photoUrl} alt={`Salon, ${location.city}`} />
      </div>}
      <div css={styles.detailsContainer}>
        <div>{location.city}</div>
        <div css={styles.addressContainer}>
          <div>
            {location.buildingNameNumber} {location.street}
          </div>
          <div>{location.postcode}</div>
        </div>
        <ButtonWithSpinner
          css={styles.button}
          loading={isSelected}
          type="button"
          onClick={() => {
            analyticsEvent(events.buttons.selectLocation(location.id));
            setIsSelected(true);
            onSelect(location.id);
          }}
        >
          Select
        </ButtonWithSpinner>
      </div>
    </div>
  );
};

export default LocationCard;
