// Polyfills
import 'core-js/features/array/flat';
import 'core-js/features/array/flat-map';

import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import Honeybadger from '@honeybadger-io/js';
import ErrorBoundary from '@honeybadger-io/react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './components/App';
import * as serviceWorker from './serviceWorker';
import UserErrorBoundary from './components/pages/user-error-boundary';

const honeybadger = Honeybadger.configure({
  apiKey: process.env.REACT_APP_HONEYBADGER_API_KEY as string,
  environment: process.env.NODE_ENV,
  disabled: process.env.NODE_ENV === 'development',
  revision: process.env.RENDER_GIT_COMMIT || 'master',
}) as Honeybadger;

ReactDOM.render(
  <ErrorBoundary honeybadger={honeybadger} ErrorComponent={UserErrorBoundary}>
    <React.StrictMode>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </React.StrictMode>
  </ErrorBoundary>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
