import colours from 'styles/colour';
import sizes from 'styles/sizes';
import shadow from 'styles/shadow';
import border from 'styles/border';
import mq from 'styles/breakpoints';

export default {
  container: {
    display: 'flex',
    justifyContent: 'space-evenly',
    marginBottom: sizes.spacing[600],
    boxShadow: shadow[200],
    borderRadius: border.radius,
    backgroundColor: colours.neutral[900],
    [mq[0]]: {
      marginBottom: sizes.spacing[700],
    },
  },
  imageContainer: { width: '50%' },
  image: {
    borderTopLeftRadius: border.radius,
    borderBottomLeftRadius: border.radius,
    width: '100%',
    height: '100%',
    objectFit: 'cover' as const,
  },
  detailsContainer: {
    alignSelf: 'center',
    padding: sizes.spacing[400],
    width: '50%',
    textAlign: 'center' as const,
  },
  addressContainer: {
    marginTop: sizes.spacing[100],
    color: colours.neutral[400],
    fontSize: sizes.font[100],
    [mq[0]]: {
      fontSize: sizes.font[200],
    },
  },
  button: {
    marginTop: sizes.spacing[400],
    marginLeft: 'auto',
    marginRight: 'auto',
  },
};
