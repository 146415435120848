import colours from 'styles/colour';
import sizes from 'styles/sizes';

export default {
  container: { maxWidth: sizes.spacing[1400], marginLeft: 'auto', marginRight: 'auto' },
  errorText: {
    color: colours.danger,
    fontSize: sizes.font[500],
  },
  headerText: {
    color: colours.neutral[300],
    fontWeight: 400,
    marginBottom: sizes.spacing[200],
  },
};
