/** @jsx jsx  */
import { jsx } from '@emotion/react';
import { Header } from 'components/common';
import PageContent from 'components/page-content';
import React from 'react';

export const AppointmentManagementLayout: React.FC = ({ children }) => {
  return (
    <main>
      <Header>Manage Appointment</Header>
      <PageContent>{children}</PageContent>
    </main>
  );
};
