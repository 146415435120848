/** @jsx jsx  */
import { jsx } from '@emotion/react';
import React from 'react';
import { ActionSuccess } from 'components/action-success';
import { pages, usePage } from 'components/analytics';
import NewAppointmentHeading from 'components/new-appointment-heading';
import SectionHeading from 'components/section-heading';

const BookingConfirmation: React.FC = () => {
  usePage(pages.bookingConfirmation);

  return (
    <React.Fragment>
      <NewAppointmentHeading />
      <SectionHeading>Booking confirmation</SectionHeading>
      <ActionSuccess titleText="Booking confirmed" text="We have sent you a confirmation email." />
    </React.Fragment>
  );
};

export default BookingConfirmation;
