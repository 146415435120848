/** @jsx jsx  */
import { jsx } from '@emotion/react';
import React, { useState } from 'react';
import requests from 'utils/requests';
import { useBooking } from 'components/booking-context';
import ErrorDisplay from 'components/error-display';
import { ButtonSecondary } from 'components/common/button';
import { useQuery } from 'react-query';
import { analyticsEvent, events, pages, usePage } from 'components/analytics';
import { RefreshCw } from 'lucide-react';
import { LoadingSpinnerWithText } from 'components/loading-spinner';
import NewAppointmentHeading from 'components/new-appointment-heading';
import SectionHeading from 'components/section-heading';
import { VerticalStaffMemberCard, HorizontalStaffMemberCard } from 'components/staff-member-card';
import styles from './staff-select-styles';
import { routes } from '../../../../constants';
import { StaffApiResponse } from '../../../../typings';

const StaffSelect: React.FC = () => {
  const { updateBooking, booking } = useBooking();
  const [updateErrors, setUpdateErrors] = useState<string[] | null>(null);
  usePage(pages.selectStylist);

  const { data, error: fetchErrors } = useQuery<StaffApiResponse, string[]>(
    [routes.api.staff, booking.location],
    () => requests.get<StaffApiResponse>(routes.api.staff),
    { staleTime: 60000 * 60 }
  );

  const handleSelectStaffMember = async (userId: number) => {
    try {
      await updateBooking({ userId });
    } catch (errors) {
      analyticsEvent(events.errors.staff(errors));
      setUpdateErrors(errors);
    }
  };

  if (fetchErrors || updateErrors)
    return (
      <ErrorDisplay errors={fetchErrors ?? ([] || updateErrors) ?? []}>
        <ButtonSecondary onClick={() => window.location.reload()} css={styles.errorButton}>
          <RefreshCw css={styles.errorButtonIcon} />
          Try again
        </ButtonSecondary>
      </ErrorDisplay>
    );
  if (!data) return <LoadingSpinnerWithText text="Loading stylists..." />;

  const shouldShowHorizontalCards = data.staff.some((staffMember) => staffMember.bio);

  const StaffMemberLayoutComponent = shouldShowHorizontalCards ? HorizontalStaffMemberCard : VerticalStaffMemberCard;

  return (
    <React.Fragment>
      <NewAppointmentHeading />
      <SectionHeading>Choose a stylist</SectionHeading>
      <div css={styles.staffMemberContainer}>
        {data.staff.map((staffMember) => (
          <StaffMemberLayoutComponent
            key={staffMember.id}
            onSelect={handleSelectStaffMember}
            staffMember={staffMember}
          />
        ))}
      </div>
    </React.Fragment>
  );
};

export default StaffSelect;
