import { keyframes } from '@emotion/react';
import colours from 'styles/colour';
import sizes from 'styles/sizes';

const bounce = keyframes`
0% {
    transform: translateY(0%)
}
50% {
    transform: translateY(-7%)
}
100% {
    transform: translateY(0)
}
`;

const spin = keyframes`
0% {
    transform: rotate(0deg)
}
100% {
    transform: rotate(360deg)
}
`;

export default {
  text: {
    fontSize: sizes.font[300],
    color: colours.neutral[100],
  },
  title: {
    color: colours.primary[500],
    fontSize: sizes.font[300],
    fontWeight: 400,
    marginBottom: sizes.spacing[200],
  },
  container: {
    display: 'flex',
  },
  icon: {
    animation: `${spin} 0.8s ease 0s, ${bounce} 0.4s ease 1s 2`,
    color: colours.primary[600],
    marginRight: sizes.spacing[300],
    alignSelf: 'center',
  },
};
