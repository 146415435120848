/** @jsx jsx  */
import { jsx } from '@emotion/react';
import { baseInputStyles } from 'components/common/text-input';
import PhoneInput, { PhoneInputProps } from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const COUNTRY_FLAG_OFFSET = '48px';

interface IMobilePhoneInput extends PhoneInputProps {
  onChange(value: string): void;
  location?: string;
}

const MobilePhoneInput: React.FC<IMobilePhoneInput> = ({ onChange, location, ...props }) => (
  <PhoneInput
    country={location?.toLowerCase() || 'gb'}
    countryCodeEditable={false}
    inputStyle={{ ...baseInputStyles, paddingLeft: COUNTRY_FLAG_OFFSET }}
    onChange={(phoneNumber) => onChange(`+${phoneNumber}`)}
    {...props}
  />
);

export default MobilePhoneInput;
