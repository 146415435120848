import colours from 'styles/colour';
import { SECTION_SPACING } from 'styles/common';
import sizes from 'styles/sizes';

export default {
  container: {
    marginBottom: SECTION_SPACING,
  },
  heading: {
    marginBottom: 0,
  },
  headingContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: sizes.spacing[500],
  },
  sectionHeading: {
    fontSize: sizes.font[100],
    fontWeight: 'bold' as const,
    color: colours.neutral[400],
    marginBottom: 0,
  },
  smallText: {
    color: colours.neutral[400],
    fontSize: sizes.font[100],
  },
  servicesListContainer: {
    margin: 0,
    width: '100%',
    listStyleType: 'none',
    paddingInlineStart: 0,
    'li:not(:last-child)': {
      marginBottom: sizes.spacing[200],
    },
  },
  servicesContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
  serviceAndPriceRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  costSection: {
    textAlign: 'right' as const,
  },
};
