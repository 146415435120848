/** @jsx jsx  */
import { jsx } from '@emotion/react';
import React from 'react';
import styles from './loading-spinner.styles';

interface ILoadingSpinner {
  colour?: string;
  size?: 8 | 16 | 24 | 32 | 40 | 46 | 64 | 72;
}

const LoadingSpinner: React.FC<ILoadingSpinner> = ({ size, colour }) => {
  return (
    <div css={styles.container(size, colour)}>
      <div />
      <div />
      <div />
      <div />
    </div>
  );
};

export default LoadingSpinner;

interface ILoadingSpinnerWithText extends ILoadingSpinner {
  text?: string;
}

export const LoadingSpinnerWithText: React.FC<ILoadingSpinnerWithText> = ({ size, colour, text = 'Loading...' }) => {
  return (
    <div css={{ textAlign: 'center' }}>
      <LoadingSpinner size={size} colour={colour} />
      <div>{text}</div>
    </div>
  );
};
