/** @jsx jsx  */
import { jsx } from '@emotion/react';
import React from 'react';
import styles from './header.styles';

export const Header: React.FC = ({ children, ...props }) => (
  <header css={styles} {...props}>
    {children}
  </header>
);
