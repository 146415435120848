import colours from '../../styles/colour';
import sizes from '../../styles/sizes';

const styles = {
  avatarAndName: {
    display: 'flex',
    alignItems: 'center',
  },
  staffAvatar: {
    border: '1px solid',
    borderColor: colours.primary[500],
    marginRight: sizes.spacing[200],
  },
  withText: {
    fontSize: sizes.font[100],
    color: colours.neutral[400],
  },
  nameText: {
    fontSize: sizes.font[200],
  },
};

export default styles;
