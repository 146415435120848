/** @jsx jsx  */
import { jsx } from '@emotion/react';
import React from 'react';
import { pages, usePage } from 'components/analytics';
import * as commonStyles from 'styles/common';
import { CheckCircle } from 'lucide-react';
import styles from './action-success.styles';

interface Props {
  titleText: string;
  text: string;
}

export const ActionSuccess: React.FC<Props> = ({ titleText, text }) => {
  usePage(pages.bookingConfirmation);

  return (
    <div css={[commonStyles.card, styles.container]}>
      <CheckCircle size={68} css={styles.icon} />
      <div>
        <h3 css={styles.title}>{titleText}</h3>
        <div css={styles.text}>{text}</div>
      </div>
    </div>
  );
};
