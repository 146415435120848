import colours from 'styles/colour';
import shadow from 'styles/shadow';
import sizes from 'styles/sizes';

export default {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  minHeight: sizes.spacing[700],
  background: colours.neutral[900],
  color: colours.neutral[100],
  paddingLeft: sizes.spacing[400],
  paddingRight: sizes.spacing[400],
  paddingTop: sizes.spacing[200],
  paddingBottom: sizes.spacing[200],
  marginBottom: sizes.spacing[400],
  boxShadow: shadow[100],
};
