import { CSSObject, keyframes } from '@emotion/react';
import colours from 'styles/colour';

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export default {
  container: (size = 32, colour: string = colours.primary[500]): CSSObject => ({
    display: 'inline-block' as const,
    position: 'relative' as const,
    width: `${size + size / 4}px`,
    height: `${size + size / 4}px`,
    ' div': {
      boxSizing: 'border-box' as const,
      display: 'block' as const,
      position: 'absolute' as const,
      width: `${size}px`,
      height: `${size}px`,
      margin: `${size / 8}px`,
      border: `${size / 8}px solid ${colour}`,
      borderRadius: '50%',
      animation: `${spin} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite`,
      borderColor: `${colour} transparent transparent transparent`,
    },
    ' div:nth-of-type(1)': {
      animationDelay: '-0.45s',
    },
    ' div:nth-of-type(2)': {
      animationDelay: '-0.3s',
    },
    ' div:nth-of-type(3)': {
      animationDelay: '-0.15s',
    },
  }),
};
