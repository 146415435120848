import colours from 'styles/colour';
import sizes from 'styles/sizes';
import shadow from 'styles/shadow';
import mq from 'styles/breakpoints';

export default {
  horizontalStaffMemberCard: {
    marginBottom: sizes.spacing[600],
    display: 'flex',
    alignItems: 'center',
    gap: sizes.spacing[400],
    width: '100%',
    maxWidth: sizes.spacing[1400],
  },
  avatar: {
    boxShadow: shadow[100],
    flex: '0 0 auto',
  },
  detailsContainer: {
    flex: '1 1 auto',
  },
  avatarSize: sizes.spacing[900],
  nameAndTitleContainer: {
    marginBottom: sizes.spacing[200],
  },
  staffName: {
    fontSize: sizes.font[500],
  },
  jobTitle: {
    color: colours.neutral[300],
    [mq[0]]: {
      fontSize: sizes.font[200],
    },
  },
  bio: {
    wordBreak: 'break-word' as const,
  },
  moreButton: {
    textDecoration: 'underline' as const,
    color: colours.blue[100],
    fontWeight: 'bold' as const,
  },
  buttonContainer: {
    marginTop: sizes.spacing[400],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
};
