/** @jsx jsx  */
import { jsx } from '@emotion/react';
import React, { useState } from 'react';
import Avatar from 'components/avatar';
import * as commonStyles from 'styles/common';
import { ButtonNaked, ButtonWithSpinner } from 'components/common/button';
import { analyticsEvent, events } from 'components/analytics';
import { DEFAULT_STAFF_PHOTO_URL } from '../../constants';
import styles from './horizontal-staff-member-card.styles';
import { StaffMemberCardProps } from './types';

const MAX_BIO_LENGTH = 140;

export const HorizontalStaffMemberCard: React.FC<StaffMemberCardProps> = ({ staffMember, onSelect }) => {
  const [isSelected, setIsSelected] = useState<boolean>(false);
  const [isBioExpanded, setIsBioExpanded] = useState<boolean>(false);

  const shouldInitiallyShowMoreButton = staffMember.bio && staffMember.bio.length > MAX_BIO_LENGTH;
  const bioText =
    isBioExpanded || !staffMember.bio || !shouldInitiallyShowMoreButton
      ? staffMember.bio
      : `${staffMember.bio.substring(0, MAX_BIO_LENGTH)}...`;

  return (
    <div css={[commonStyles.card, styles.horizontalStaffMemberCard]}>
      <Avatar
        css={styles.avatar}
        src={staffMember.photoUrl || DEFAULT_STAFF_PHOTO_URL}
        alt={`Stylist, ${staffMember.fullName}`}
        size={styles.avatarSize}
      />

      <div css={styles.detailsContainer}>
        <div css={styles.nameAndTitleContainer}>
          <div css={styles.staffName}>{staffMember.fullName}</div>
          <div css={styles.jobTitle}>{staffMember.jobTitle}</div>
        </div>
        <div css={styles.bio}>
          {bioText}{' '}
          {staffMember.bio && !isBioExpanded && shouldInitiallyShowMoreButton && (
            <ButtonNaked onClick={() => setIsBioExpanded(true)} css={styles.moreButton}>
              more
            </ButtonNaked>
          )}
        </div>

        <div css={styles.buttonContainer}>
          <ButtonWithSpinner
            loading={isSelected}
            type="button"
            onClick={() => {
              analyticsEvent(events.buttons.selectStaffMember(staffMember.id));
              setIsSelected(true);
              onSelect(staffMember.id);
            }}
          >
            Select
          </ButtonWithSpinner>
        </div>
      </div>
    </div>
  );
};
