import { useMutation, useQuery, useQueryClient } from 'react-query';
import { AppointmentApiResponse, CommonAPIErrors } from 'typings';
import requests from 'utils/requests';
import { routes } from './constants';

interface BaseAppoinmentVariables {
  id: number | string;
  managementToken: string;
}

type CancelAppointmentVairiables = BaseAppoinmentVariables;
type GetAppointmentVairiables = BaseAppoinmentVariables;

const cancelAppointment = ({ id, managementToken }: CancelAppointmentVairiables) =>
  requests.del(`${routes.api.appointments}/${id}?managementToken=${managementToken}`);

export const useCancelAppointment = () => {
  const queryClient = useQueryClient();
  return useMutation<Record<string, never>, CommonAPIErrors, CancelAppointmentVairiables>(cancelAppointment, {
    onMutate: () => {
      queryClient.cancelQueries(routes.api.appointments);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(routes.api.appointments);
    },
  });
};

const fetchAppointments = ({ id, managementToken }: GetAppointmentVairiables) =>
  requests.get<AppointmentApiResponse>(`${routes.api.appointments}/${id}?managementToken=${managementToken}`);

export const useAppointment = (getAppointmentParams: GetAppointmentVairiables) =>
  useQuery<AppointmentApiResponse, CommonAPIErrors>(
    [routes.api.appointments, getAppointmentParams.id],
    () => fetchAppointments(getAppointmentParams),
    {
      staleTime: 60000 * 60,
    }
  );
