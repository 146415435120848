import sizes from 'styles/sizes';

export default {
  errorButton: {
    width: '100%',
    marginTop: sizes.spacing[400],
  },
  errorButtonIcon: {
    marginRight: sizes.spacing[200],
  },
};
