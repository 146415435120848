/** @jsx jsx  */
import { jsx } from '@emotion/react';
import Dinero from 'dinero.js';
import { initializeAnalytics, pages, usePage } from 'components/analytics';
import AppointmentSummary from 'components/appointment-summary';
import ErrorDisplay from 'components/error-display';
import { LoadingSpinnerWithText } from 'components/loading-spinner';
import SectionHeading from 'components/section-heading';
import { useAppointment } from 'queries';
import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { calculateTotalServicesCost, sortAppointmentServicesByStartTime } from 'utils/helpers';
import { AppointmentCancelled } from './appointment-cancelled';
import { AppointmentManagementLayout } from './appointment-management-layout';
import { appointmentCanBeManaged, isAppointmentCancelled } from './appointment-management.helpers';
import { AppointmentNotManagable } from './appointment-not-manageable';
import { CancelAppointment } from './cancel-appointment';

interface AppointmentManagementContentProps {
  appointmentId: string;
  managementToken: string;
}

const AppointmentManagementContent: React.FC<AppointmentManagementContentProps> = ({
  appointmentId,
  managementToken,
}) => {
  const { data: appointmentData, error: appointmentErrors } = useAppointment({ id: appointmentId, managementToken });
  const [isCurrecySet, setIsCurrencySet] = useState(false);

  useEffect(() => {
    if (appointmentData) {
      Dinero.defaultCurrency = appointmentData.appointment.business.currency;
      setIsCurrencySet(true);
    }
  }, [appointmentData]);

  if (appointmentErrors) {
    return <ErrorDisplay errors={appointmentErrors} />;
  }

  if (!appointmentData || !isCurrecySet) {
    return <LoadingSpinnerWithText text="Loading your appointment..." />;
  }

  const { appointment } = appointmentData;
  const { date, location, appointmentServices, business } = appointment;

  if (isAppointmentCancelled(appointment)) {
    return <AppointmentCancelled appointment={appointment} />;
  }

  if (!appointmentCanBeManaged(appointment)) {
    return <AppointmentNotManagable appointment={appointment} />;
  }

  const services = sortAppointmentServicesByStartTime(appointmentServices).map(({ service }) => service);
  const { user, startTime } = appointmentServices[0];

  return (
    <div>
      <SectionHeading>Appointment at {business.name}</SectionHeading>
      <AppointmentSummary
        date={date}
        user={user}
        totalBookingCost={calculateTotalServicesCost(services)}
        services={services}
        location={location}
        time={startTime}
      />
      <CancelAppointment appointment={appointment} managementToken={managementToken} />
    </div>
  );
};

export const AppointmentManagement: React.FC = () => {
  initializeAnalytics();
  usePage(pages.appointmentManagement);
  const { appointmentId } = useParams<{ appointmentId: string }>();

  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const managementToken = searchParams.get('managementToken');

  return (
    <AppointmentManagementLayout>
      {!managementToken ? (
        <ErrorDisplay errors="Unauthorized: Invalid token provided" />
      ) : (
        <AppointmentManagementContent appointmentId={appointmentId} managementToken={managementToken} />
      )}
    </AppointmentManagementLayout>
  );
};
