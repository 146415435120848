/** @jsx jsx  */
import { jsx } from '@emotion/react';
import { AlertTriangle, Info } from 'lucide-react';
import styles from './styles';

const ICON_SIZE = 30;
interface Props {
  type?: 'warning' | 'info';
}

const WarningIcon = () => <AlertTriangle css={[styles.icon, styles.warningIcon]} size={ICON_SIZE} />;
const InfoIcon = () => <Info css={[styles.icon, styles.infoIcon]} size={ICON_SIZE} />;

export const InfoMessage: React.FC<Props> = ({ children, type = 'info', ...props }) => {
  let Icon = InfoIcon;

  switch (type) {
    case 'warning':
      Icon = WarningIcon;
      break;
    case 'info':
    default:
      Icon = InfoIcon;
  }

  return (
    <div css={styles.container} {...props}>
      <div>
        <Icon />
      </div>
      <div>{children}</div>
    </div>
  );
};
