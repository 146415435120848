/** @jsx jsx  */
import { jsx } from '@emotion/react';
import React, { KeyboardEvent } from 'react';
import { formatMinutesAsReadableTime } from 'utils/date';
import { formatAmountForDisplay } from 'utils/currency';
import styles from './service-option.styles';
import { Service, MultiService } from '../../typings';

const MAX_DESC_LENGTH = 100;

interface Props {
  service: Service | MultiService;
  isSelected: boolean;
  onAdd: (id: number) => void;
  onRemove: (id: number) => void;
}

const ServiceOption: React.FC<Props> = ({ service, isSelected, onAdd, onRemove }) => {
  const descText =
    service?.description.length > MAX_DESC_LENGTH
      ? `${service.description.substring(0, MAX_DESC_LENGTH)}...`
      : service.description;

  const handleContainerClick = () => {
    if (isSelected) {
      onRemove(service.id);
    } else {
      onAdd(service.id);
    }
  };

  const handleKeyPress = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === ' ' || e.key === 'Enter') {
      if (isSelected) {
        onRemove(service.id);
      } else {
        onAdd(service.id);
      }
    }
  };

  return (
    <div
      role="button"
      tabIndex={0}
      css={styles.container}
      onClick={() => handleContainerClick()}
      onKeyPress={(e) => {
        handleKeyPress(e);
      }}
    >
      <div css={styles.leftContent}>
        <div css={styles.checkbox}>
          <span css={styles.checkBoxControl(isSelected)}>
            <svg
              css={styles.tick(isSelected)}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              aria-hidden="true"
              focusable="false"
            >
              <path fill="none" stroke="currentColor" strokeWidth="3" d="M1.73 12.91l6.37 6.37L22.79 4.59" />
            </svg>
          </span>
        </div>
        <div>
          <h5 css={styles.title(isSelected)}>{service.title}</h5>
          <div css={styles.desciption}>{descText}</div>
          <div css={styles.duration}>{formatMinutesAsReadableTime(service.duration)}</div>
        </div>
      </div>

      <div css={styles.cost}>{formatAmountForDisplay(service.cost)}</div>
    </div>
  );
};

export default ServiceOption;
