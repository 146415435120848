/** @jsx jsx  */
import { jsx } from '@emotion/react';
import * as commonStyles from 'styles/common';
import { formatAmountForDisplay } from 'utils/currency';
import SectionNameHeading from 'components/section-name-heading';
import { useBooking } from 'components/booking-context';
import { calculateDepositAmountDue, calculateTotalServicesCost } from 'utils/helpers';
import styles from './deposit-summary.styles';

export const DepositSummary = () => {
  const { booking } = useBooking();
  const { depositAmount, depositType, services } = booking;
  const totalBookingCost = calculateTotalServicesCost(services);

  // TODO pub booking needs a refactor now so that initial booking doesnt have daft nulls
  if (depositAmount === null || depositType === null) {
    return null;
  }

  const depositDue = calculateDepositAmountDue(depositType, depositAmount, totalBookingCost);

  return (
    <div css={[commonStyles.card, styles.container]}>
      <SectionNameHeading css={styles.sectionHeading}>Deposit</SectionNameHeading>
      <div>
        <span css={commonStyles.amountDescriptor}>Due today:</span>{' '}
        <span css={commonStyles.featuredCost}>{formatAmountForDisplay(depositDue)}</span>
      </div>
    </div>
  );
};
