/** @jsx jsx  */
import { jsx } from '@emotion/react';
import { Card } from 'components/common/card';
import { AppointmentManagementDepositBookingPolicies } from 'components/deposit-policies';
import { DEPOSIT_CANCELLATION_POLICY_ID } from 'components/deposit-policies/deposit-policies';
import { InfoMessage } from 'components/info-message';
import SectionNameHeading from 'components/section-name-heading';
import React from 'react';
import { PublicAppointment } from 'typings';
import { CancelAppointmentControls } from '../cancel-appointment-controls';
import { ContactSalon } from '../contact-salon';
import styles from './cancel-appointment.styles';

interface CancelAppointmentProps {
  appointment: PublicAppointment;
  managementToken: string;
}

const AppointmentManagementSections: React.FC = ({ children }) => (
  <div css={styles.appointmentManagementSections}>{children}</div>
);

const AppointmentManagementHeading = () => <SectionNameHeading>Appointment Management</SectionNameHeading>;

const CancelNonDepositAppointment = ({ appointment, managementToken }: CancelAppointmentProps) => {
  const { cancellationGracePeriod, id, isCancellationPolicyBreached } = appointment;
  return (
    <AppointmentManagementSections>
      <Card>
        <AppointmentManagementHeading />
        {isCancellationPolicyBreached ? (
          <InfoMessage css={styles.infoText} type="warning">
            According to our cancellation policy, appointments can only be cancelled {cancellationGracePeriod} days
            before they are due to start.
          </InfoMessage>
        ) : (
          <CancelAppointmentControls managementToken={managementToken} appointmentId={id} />
        )}
      </Card>
      <ContactSalon appointment={appointment} />
    </AppointmentManagementSections>
  );
};

const CancelDepositAppointment = ({ appointment, managementToken }: CancelAppointmentProps) => {
  const { cancellationGracePeriod, id, isCancellationPolicyBreached } = appointment;

  return (
    <AppointmentManagementSections>
      <Card>
        <AppointmentManagementHeading />
        {isCancellationPolicyBreached ? (
          <InfoMessage css={styles.infoText} type="warning">
            According to our <a href={`#${DEPOSIT_CANCELLATION_POLICY_ID}`}>cancellation policy</a>, you will lose your
            paid deposit if you cancel this appointment.
          </InfoMessage>
        ) : (
          <InfoMessage css={styles.infoText} type="info">
            According to our <a href={`#${DEPOSIT_CANCELLATION_POLICY_ID}`}>cancellation policy</a>, you will receive a
            full refund of your deposit if you cancel this appointment.
          </InfoMessage>
        )}
        <CancelAppointmentControls managementToken={managementToken} appointmentId={id} />
      </Card>
      <ContactSalon appointment={appointment} />
      <AppointmentManagementDepositBookingPolicies cancellationGracePeriod={cancellationGracePeriod} />
    </AppointmentManagementSections>
  );
};

export const CancelAppointment: React.FC<CancelAppointmentProps> = ({ ...props }) => {
  return props.appointment.isDepositAppointment ? (
    <CancelDepositAppointment {...props} />
  ) : (
    <CancelNonDepositAppointment {...props} />
  );
};
