/** @jsx jsx  */
import { jsx } from '@emotion/react';
import { PublicAppointment } from 'typings';
import { Card } from 'components/common/card';
import SectionHeading from 'components/section-heading';
import styles from './appointment-not-manageable.styles';
import { ContactSalon } from '../contact-salon';

interface Props {
  appointment: PublicAppointment;
}

export const AppointmentNotManagable = ({ appointment }: Props) => {
  return (
    <section css={styles.section}>
      <SectionHeading>{appointment.business.name}</SectionHeading>
      <Card>The appointment cannot be managed</Card>
      <ContactSalon appointment={appointment} />
    </section>
  );
};
