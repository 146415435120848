import colours from './colour';

export const regularBorderWidth = '1px';
export const largeBorderWidth = '2px';

const border = {
  regular: `${regularBorderWidth} solid`,
  large: `${largeBorderWidth} solid`,
  seperator: `2px solid ${colours.neutral[800]}`,
  radius: '0.6rem',
};

export default border;
