const colour = {
  neutral: {
    100: 'hsla(0, 0%, 21%, 1.0)',
    200: 'hsla(3, 6%, 30%, 1.0)',
    300: 'hsla(3, 5%, 40%, 1.0)',
    400: 'hsla(3, 5%, 55%, 1.0)',
    500: 'hsla(3, 5%, 71%, 1.0)',
    600: 'hsla(3, 6%, 82%, 1.0)',
    700: 'hsla(4, 8%, 89%, 1.0)',
    800: 'hsla(6, 10%, 98%, 1.0)',
    900: 'hsla(3, 14%, 99%, 1.0)',
  },
  primary: {
    100: 'hsla(1, 40%, 20%, 1.0)',
    200: 'hsla(1, 40%, 34%, 1.0)',
    300: 'hsla(2, 42%, 47%, 1.0)',
    400: 'hsla(2, 60%, 60%, 1.0)',
    500: 'hsla(3, 92%, 70%, 1.0)',
    600: 'hsla(3, 92%, 82%, 1.0)',
    700: 'hsla(3, 96%, 87%, 1.0)',
    800: 'hsla(3, 98%, 94%, 1.0)',
    900: 'hsla(3, 100%, 97%, 1.0)',
  },
  blue: {
    50: '#1E3A8A',
    100: '#1E40AF',
    200: '#1D4ED8',
    300: '#2563EB',
    400: '#3B82F6',
    500: '#60A5FA',
    600: '#93C5FD',
    700: '#BFDBFE',
    800: '#DBEAFE',
    900: '#EFF6FF',
  },
  warning: {
    50: '#78350F',
    100: '#92400E',
    200: '#B45309',
    300: '#D97706',
    400: '#F59E0B',
    500: '#FBBF24',
    600: '#FCD34D',
    700: '#FDE68A',
    800: '#FEF3C7',
    900: '#FFFBEB',
  },
  danger: '#FF6663',
  success: '#4DCE3B',
  trafficAmber: '#feb144',
  trafficGreen: '#9EE09E',
};

export default colour;

export const calendarColours = [
  { colour: 'Violet', hex: 'hsl(268, 89%, 89%)' },
  { colour: 'Yellow', hex: 'hsl(49, 97%, 73%)' },
  { colour: 'Blue', hex: 'hsl(219, 92%, 81%)' },
  { colour: 'Green', hex: 'hsl(162, 84%, 71%)' },
  { colour: 'Purple', hex: 'hsl(0, 100%, 87%)' },
];
