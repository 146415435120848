import colour from 'styles/colour';
import sizes from 'styles/sizes';

export default {
  contactSalon: {
    fontSize: sizes.font[300],
  },
  contactSalonDetail: {
    textAlign: 'center' as const,
    color: colour.neutral[300],
  },
};
