import colour from 'styles/colour';
import sizes from 'styles/sizes';

export default {
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  icon: {
    marginRight: sizes.spacing[200],
  },
  warningIcon: {
    color: colour.warning[500],
  },
  infoIcon: {
    color: colour.neutral[400],
  },
};
