/** @jsx jsx */
import { jsx } from '@emotion/react';
import styles from './avatar.styles';

interface Props {
  src: string;
  alt: string;
  size: string;
}

const Avatar: React.FC<Props> = ({ src, alt, size, ...props }) => {
  return <img src={src} css={styles.avatar(size)} alt={`${alt} avatar`} {...props} />;
};

export default Avatar;
