/** @jsx jsx  */
import { jsx } from '@emotion/react';
import React from 'react';
import styles from './content-container.styles';

const PageContent: React.FC = ({ children }) => (
  <section css={styles.section}>
    <div css={styles.container}>{children}</div>
  </section>
);

export default PageContent;
