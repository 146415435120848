/** @jsx jsx  */
import { jsx } from '@emotion/react';
import { ChevronRight } from 'lucide-react';
import ReactGA from 'react-ga';
import React, { useState } from 'react';
import { ButtonWithSpinner } from 'components/common/button';
import { analyticsEvent } from 'components/analytics';
import styles from './next-stage-footer.styles';

interface Props {
  introText?: string;
  nextButtonText?: string;
  onNextClick: () => void;
  nextStageAnalyticsEvent?: ReactGA.EventArgs;
}

const NextStageFooter: React.FC<Props> = ({
  introText,
  nextButtonText,
  children,
  onNextClick,
  nextStageAnalyticsEvent,
}) => {
  const [nextClicked, setNextClicked] = useState<boolean>(false);
  return (
    <footer css={styles.container}>
      <div>
        <div css={styles.infoText}>{introText || 'You have selected'}</div>
        <div css={styles.content}>{children}</div>
      </div>
      <ButtonWithSpinner
        loading={nextClicked}
        onClick={() => {
          if (nextStageAnalyticsEvent) {
            analyticsEvent(nextStageAnalyticsEvent);
          }
          setNextClicked(true);
          onNextClick();
        }}
        type="button"
      >
        {nextButtonText || 'Next'} <ChevronRight css={styles.buttonIcon} size={16} />
      </ButtonWithSpinner>
    </footer>
  );
};

export default NextStageFooter;
