/** @jsx jsx  */
import { jsx } from '@emotion/react';
import React, { useState, KeyboardEvent } from 'react';
import { useQuery } from 'react-query';
import requests from 'utils/requests';
import { analyticsEvent, events, pages, usePage } from 'components/analytics';
import { formatDateForLocale } from 'utils/date';
import { AvailabilityApiResponse } from 'typings';
import { RefreshCw } from 'lucide-react';
import NextStageFooter from 'components/next-stage-footer';
import { useBooking } from 'components/booking-context';
import { LoadingSpinnerWithText } from 'components/loading-spinner';
import * as commonStyles from 'styles/common';
import ErrorDisplay from 'components/error-display';
import { ButtonSecondary } from 'components/common/button';
import { routes } from '../../../../constants';
import NewAppointmentHeading from '../../../new-appointment-heading';
import SectionHeading from '../../../section-heading';
import styles from './time-select.styles';

const TimeSelect: React.FC = () => {
  const { updateBooking, booking } = useBooking();
  const [updateErrors, setUpdateErrors] = useState<string[] | null>(null);
  const [selectedTime, setSelectedTime] = useState<string | null>(null);

  usePage(pages.selectTime);

  const { data, error: fetchErrors } = useQuery<AvailabilityApiResponse, string[]>(
    [
      routes.api.availability,
      booking.location,
      booking.user?.id,
      booking.services.map((s) => `service-${s.id}`).join(''),
      booking.date,
    ],
    () => requests.get<AvailabilityApiResponse>(routes.api.availability),
    { staleTime: 30000 }
  );

  const handleSelectTime = async () => {
    try {
      await updateBooking({ time: selectedTime });
    } catch (errors) {
      analyticsEvent(events.errors.time(errors));
      setUpdateErrors(errors);
    }
  };

  const handleKeyPress = (e: KeyboardEvent<HTMLDivElement>, time: string) => {
    if (e.key === ' ' || e.key === 'Enter') {
      setSelectedTime(time);
    }
  };

  if (fetchErrors || updateErrors)
    return (
      <ErrorDisplay errors={(fetchErrors ?? []) || (updateErrors ?? [])}>
        <ButtonSecondary onClick={() => window.location.reload()} css={styles.errorButton}>
          <RefreshCw css={styles.errorButtonIcon} />
          Try again
        </ButtonSecondary>
      </ErrorDisplay>
    );

  if (!data) return <LoadingSpinnerWithText text="Loading availability..." />;
  return (
    <React.Fragment>
      <NewAppointmentHeading />
      <SectionHeading>Choose a time</SectionHeading>
      {data.availability.length > 0 ? (
        <div css={styles.timesContainer}>
          {data.availability.map((slot) => (
            <div
              key={slot.slotStart}
              css={styles.timeBox(selectedTime === slot.slotStart)}
              onClick={() => setSelectedTime(slot.slotStart)}
              role="button"
              tabIndex={0}
              onKeyPress={(e) => {
                handleKeyPress(e, slot.slotStart);
              }}
            >
              {slot.slotStart}
            </div>
          ))}
        </div>
      ) : (
        <div css={commonStyles.card}>There are no available timeslots for this date.</div>
      )}

      {selectedTime && (
        <NextStageFooter
          nextStageAnalyticsEvent={events.buttons.selectTime(selectedTime)}
          onNextClick={handleSelectTime}
        >
          {selectedTime} on {formatDateForLocale(booking.date as string)}
        </NextStageFooter>
      )}
    </React.Fragment>
  );
};

export default TimeSelect;
