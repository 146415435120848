/** @jsx jsx  */
import { jsx } from '@emotion/react';
import { Card } from 'components/common/card';
import SectionNameHeading from 'components/section-name-heading';
import { PublicAppointment } from 'typings';
import styles from './contact-salon.styles';

interface Props {
  appointment: PublicAppointment;
}

export const ContactSalon = ({ appointment, ...props }: Props) => {
  return (
    <Card css={styles.contactSalon} {...props}>
      <SectionNameHeading>Contact</SectionNameHeading>
      <div css={styles.contactSalonDetail}>{appointment.business.name}</div>
      <div css={styles.contactSalonDetail}>{appointment.location.city}</div>
      <div css={styles.contactSalonDetail}>{appointment.location.phoneNumber}</div>
    </Card>
  );
};
