/** @jsx jsx  */
import { jsx } from '@emotion/react';
import { useBooking } from 'components/booking-context';
import { Header } from 'components/common';
import { ButtonNaked } from 'components/common/button';
import PageContent from 'components/page-content';
import React from 'react';
import { ChevronLeft } from 'lucide-react';
import { useLocation } from 'react-router-dom';
import styles from './public-booking-layout.styles';
import { routes } from '../../../../constants';

const PublicBookingLayout: React.FC = ({ children }) => {
  const { booking, back, isLoading, isBookingComplete, error } = useBooking();
  const location = useLocation();
  const isAfterFirstStage = Boolean(booking.location);
  const isOnBookingSuccessStage = location.pathname.includes(routes.app.bookingSuccess);

  return (
    <main>
      <Header css={styles.header}>
        {!isBookingComplete && isAfterFirstStage && !isOnBookingSuccessStage && !isLoading && !error && (
          <ButtonNaked onClick={() => back()} css={styles.backButton}>
            <ChevronLeft />
          </ButtonNaked>
        )}
        <div>{booking.businessName}</div>
      </Header>

      <PageContent>{children}</PageContent>
    </main>
  );
};

export default PublicBookingLayout;
