import colours from 'styles/colour';
import sizes from 'styles/sizes';

export default {
  heading: {
    fontSize: sizes.font[500],
    color: colours.neutral[200],
    marginBottom: sizes.spacing[600],
    fontWeight: 500,
    letterSpacing: 0.1,
  },
};
