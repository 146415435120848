/** @jsx jsx */
import { jsx } from '@emotion/react';
import React from 'react';
import { StaffMember } from 'typings';
import Avatar from '../avatar/avatar';
import styles from './avatar-and-name.styles';

interface Props {
  staffMember: StaffMember;
}

const AvatarAndName: React.FC<Props> = ({ staffMember }) => {
  const { fullName, photoUrl } = staffMember;
  return (
    <div css={styles.avatarAndName}>
      <Avatar alt={`Staff avatar for ${fullName}`} css={styles.staffAvatar} size="20px" src={photoUrl} />
      <div>
        <div css={styles.nameText}>{fullName}</div>
      </div>
    </div>
  );
};

export default AvatarAndName;
