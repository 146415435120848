/** @jsx jsx  */
import { jsx } from '@emotion/react';
import { ActionSuccess } from 'components/action-success';
import { Button } from 'components/common';
import { useHistory } from 'react-router-dom';
import { PublicAppointment } from 'typings';
import { buildBusinessNewAppointmentRoute } from 'utils/helpers';
import styles from './appointment-cancelled.styles';

interface AppointmentCancelledProps {
  appointment: PublicAppointment;
}

export const AppointmentCancelled: React.FC<AppointmentCancelledProps> = ({ appointment }) => {
  const history = useHistory();
  const newAppointmentRoute = buildBusinessNewAppointmentRoute(appointment.business.slug);

  return (
    <section>
      <ActionSuccess titleText="Appointment cancelled" text="Your appointment has been cancelled" />
      <div css={styles.newAppointmentButtonContainer}>
        <Button onClick={() => history.push(newAppointmentRoute)}>Book a new appointment</Button>
      </div>
    </section>
  );
};
