/** @jsx jsx  */
import { jsx } from '@emotion/react';
import React from 'react';
import * as commonStyles from 'styles/common';
import styles from './error-display.styles';

interface Props {
  errors: string | string[] | Error;
}

const ErrorDisplay: React.FC<Props> = ({ errors, children }) => {
  let errorMessage;

  if (typeof errors === 'string') {
    errorMessage = errors;
  } else if (errors instanceof Array) {
    errorMessage = errors.map((e) => <div key={`error-message-${Date.now()}`}>- {e}</div>);
  } else {
    errorMessage = errors.message;
  }

  return (
    <div css={[commonStyles.card, styles.container]}>
      <h4 css={styles.headerText}>There was an error...</h4>
      <div css={styles.errorText}>{errorMessage}</div>
      {children}
    </div>
  );
};

export default ErrorDisplay;
