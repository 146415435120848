import sizes from 'styles/sizes';

export default {
  staffMemberContainer: {
    display: 'flex',
    flexWrap: 'wrap' as const,
    justifyContent: 'space-evenly',
  },
  errorButton: {
    width: '100%',
    marginTop: sizes.spacing[400],
  },
  errorButtonIcon: {
    marginRight: sizes.spacing[200],
  },
};
