/** @jsx jsx  */
import { jsx } from '@emotion/react';
import { analyticsEvent, events } from 'components/analytics';
import { Button, ButtonDanger } from 'components/common';
import ErrorDisplay from 'components/error-display';
import { useCancelAppointment } from 'queries';
import { useState } from 'react';
import { X } from 'lucide-react';
import styles from './cancel-appointment-controls.styles';

interface CancelAppointmentControlsProps {
  appointmentId: number;
  managementToken: string;
}

export const CancelAppointmentControls: React.FC<CancelAppointmentControlsProps> = ({
  appointmentId,
  managementToken,
}) => {
  const cancelAppointment = useCancelAppointment();
  const [isCancelling, setIsCancelling] = useState(false);

  const handleConfirmCancelButtonClick = async () => {
    try {
      analyticsEvent(events.buttons.cancelAppointment());
      await cancelAppointment.mutateAsync({ id: appointmentId, managementToken });
    } catch (apiErrors) {
      analyticsEvent(events.errors.cancelAppointment(apiErrors as string[]));
    }
  };

  if (cancelAppointment.error) {
    return <ErrorDisplay errors={cancelAppointment.error} />;
  }

  return isCancelling ? (
    <div>
      <p css={styles.cancelText}>Are you sure you wish to cancel your appointment?</p>
      <div css={styles.confirmCancelButtonContainer}>
        <Button onClick={() => setIsCancelling(false)}>No</Button>
        <ButtonDanger onClick={handleConfirmCancelButtonClick}>Yes</ButtonDanger>
      </div>
    </div>
  ) : (
    <div css={styles.cancelButtonContainer}>
      <Button onClick={() => setIsCancelling(true)}>
        <X css={styles.cancelIcon} size={16} /> Cancel appointment
      </Button>
    </div>
  );
};
