/** @jsx jsx */
import { jsx } from '@emotion/react';
import styles from './section-heading.styles';

const SectionHeading: React.FC = ({ children, ...props }) => {
  return (
    <h3 css={styles.heading} {...props}>
      {children}
    </h3>
  );
};

export default SectionHeading;
