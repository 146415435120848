import styled from '@emotion/styled';
import colours from 'styles/colour';
import sizes from 'styles/sizes';

export const baseInputStyles = {
  marginBottom: sizes.spacing[300],
  fontSize: sizes.font[200],
  color: colours.neutral[100],
};

export const Label = styled.label(() => ({
  ...baseInputStyles,
}));
