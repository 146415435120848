import { format, formatDistanceStrict } from 'date-fns';
import DEFAULT_LOCALE from 'date-fns/locale/en-GB';

const DEFAULT_FORMAT_STRING = 'PPPP';

export const formatDateForStore = (date: Date): string => format(date, 'yyyy-MM-dd');

export const formatTimeForStore = (date: Date): string => format(date, 'HH:mm');

// We need to do `new Date(Number(year), Number(month) - 1, Number(day))` when parsing date strings because of
// https://stackoverflow.com/questions/7556591/is-the-javascript-date-object-always-one-day-off
export const newTimeZoneAgnosticDateFromDateString = (date: string) => {
  const [year, month, day] = date.split('-');
  return new Date(Number(year), Number(month) - 1, Number(day));
};

export const formatDateForLocale = (
  date: Date | string,
  formatString = DEFAULT_FORMAT_STRING,
  locale = DEFAULT_LOCALE
): string => {
  if (typeof date === 'string') {
    return format(newTimeZoneAgnosticDateFromDateString(date), formatString, { locale });
  }
  return format(date, formatString, { locale });
};

export const formatMinutesAsReadableTime = (minutes: number, locale: Locale = DEFAULT_LOCALE): string => {
  const hours = Math.floor(minutes / 60);

  if (hours < 1) {
    return formatDistanceStrict(0, minutes * 60 * 1000, { locale, unit: 'minute' });
  }
  const hoursString = formatDistanceStrict(0, hours * 60 * 60 * 1000, { locale, unit: 'hour' });
  const hoursInMinutes = hours * 60;
  const remainderMinutes = minutes - hoursInMinutes;

  if (remainderMinutes === 0) {
    return hoursString;
  }
  const minutesString = formatDistanceStrict(0, remainderMinutes * 60 * 1000, { locale, unit: 'minute' });
  return `${hoursString} ${minutesString}`;
};
