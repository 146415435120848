import sizes from 'styles/sizes';

export default {
  newAppointmentButtonContainer: {
    marginTop: sizes.spacing[500],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
};
