const API_BASE = process.env.NODE_ENV === 'production' ? 'https://api.saloncloud.com' : '';
const API_PATH = '/api/public/v1';

export const DEFAULT_STAFF_PHOTO_URL = 'https://sapply-public.s3.eu-west-2.amazonaws.com/avatar-placeholder.png';

export const routes = {
  app: {
    home: '/',
    confirmAndBook: '/confirm-and-book',
    bookingSuccess: '/booking/success',
  },
  api: {
    publicBooking: `${API_BASE}${API_PATH}/public-booking`,
    locations: `${API_BASE}${API_PATH}/locations`,
    services: `${API_BASE}${API_PATH}/services`,
    staff: `${API_BASE}${API_PATH}/staff`,
    dates: `${API_BASE}${API_PATH}/dates`,
    availability: `${API_BASE}${API_PATH}/availability`,
    appointments: `${API_BASE}${API_PATH}/appointments`,
  },
};

export const strings = {
  supportEmail: 'support@saloncloud.com',
};
