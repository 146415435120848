import sizes from 'styles/sizes';

export default {
  section: {
    marginLeft: sizes.spacing[400],
    marginRight: sizes.spacing[400],
  },
  container: {
    maxWidth: sizes.spacing[1400],
    marginLeft: 'auto',
    marginRight: 'auto',
  },
};
