import { CSSObject } from '@emotion/react';
import { regularBorderWidth } from 'styles/border';
import colours from 'styles/colour';
import sizes from 'styles/sizes';
import mq from 'styles/breakpoints';

export default {
  timeBox: (isSelected: boolean): CSSObject => ({
    textAlign: 'center' as const,
    color: isSelected ? colours.primary[400] : colours.neutral[100],
    background: isSelected ? colours.primary[900] : colours.neutral[900],
    padding: sizes.spacing[400],
    borderColor: colours.neutral[700],
    borderWidth: regularBorderWidth,
    borderBottomStyle: 'solid' as const,
    cursor: 'pointer',
    [mq[0]]: {
      borderLeftStyle: 'solid' as const,
      borderRightStyle: 'solid' as const,
    },
    ':first-of-type': {
      borderTopStyle: 'solid' as const,
    },
  }),
  timesContainer: {
    // TODO possibly make this a constant reference so that negative gutters remain consistent after changes
    marginRight: `-${sizes.spacing[400]}`,
    marginLeft: `-${sizes.spacing[400]}`,
    marginBottom: sizes.spacing[800],
    backgroundColor: colours.neutral[800],
  },
  errorButton: {
    width: '100%',
    marginTop: sizes.spacing[400],
  },
  errorButtonIcon: {
    marginRight: sizes.spacing[200],
  },
};
