import colours from 'styles/colour';
import sizes from 'styles/sizes';

export default {
  heading: {
    fontSize: sizes.font[300],
    color: colours.neutral[400],
    marginBottom: sizes.spacing[200],
    fontWeight: 500,
    letterSpacing: 0.1,
  },
};
