import { SECTION_SPACING } from 'styles/common';
import sizes from 'styles/sizes';

export default {
  appointmentManagementSections: {
    display: 'flex',
    flexDirection: 'column' as const,
    gap: SECTION_SPACING,
  },
  infoText: {
    marginBottom: sizes.spacing[500],
  },
};
