import * as yup from 'yup';

const phoneRegExp = /^[+]?([0-9][\s]?|[0-9]?)([(][0-9]{3}[)][\s]?|[0-9]{3}[-\s.]?)[0-9]{3}[-\s.]?[0-9]{4,6}$/im;

export const schema = yup
  .object({
    firstName: yup.string().required('A first name is required'),
    lastName: yup.string().required('A last name is required'),
    mobileNumber: yup
      .string()
      .required('A mobile number is required')
      .matches(phoneRegExp, 'The phone number must be a valid mobile number'),
    email: yup.string().required('An email is required').email('The email must be a valid email address'),
  })
  .required();
